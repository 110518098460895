<template>
    <div class="sub-com">
        <div class="containers set-het">
            <p class="top_cam">Our Subsidiary Companies </p>
            <div class="row" style="padding-bottom: 20px;">
                <div class="col-lg-3 col-md-3 col-sm-6" v-for="data in inforcompany" :key="data.name"  @click="routeUrl(data.url)">
                    <div class="card-company">
                        <div class="">
                            <img :src="data.logo" alt="">
                        </div>
                        <div class="title-company">
                            <span>{{ data.name }}</span>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
           inforcompany:[
                {
                    "url":"https://www.buvasea.com/",
                    "name":"Buva Sea",
                    "logo":require('@/assets/company_subsidiary/buva.svg'),
                },
                {
                    "url":"",
                    "name":"VET Air Bus",
                    "logo":require('@/assets/company_subsidiary/vet_air.svg'),
                },
                {
                    "url":"/resort",
                    "name":"Resort",
                    "logo":require('@/assets/company_subsidiary/resort.svg'),
                },
                {
                    "url":"https://www.vtenh.com/en/",
                    "name":"VTENH",
                    "logo":require('@/assets/company_subsidiary/vtenh.svg'),
                },
               
        ],
        }
    },
    methods: {
        routeUrl(url){
            if(url==''){
                console.log('url is empty');
            }else if (url.startsWith('http://') || url.startsWith('https://')) {
                window.open(url, '_blank');
            } else {
                const baseUrl = window.location.origin;
                window.open(`${baseUrl}${url}`, '_blank');
                // this.$router.push(url);
            }
        }
    },
    created() {
        
    }
}
</script>

<style scoped>
    .sub-com {
        padding-bottom: 50px;
        background: #F2F2F2;
    }
    .set-het {
        height: 100%;
    }
    .top_cam {
        font-size: 20px;
        font-weight: 700;
    }
    .card-company{
        background: #FFFFFF;
        opacity: 1;
        border: 1px solid #C6C6C640;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 22px 25px 13px 25px;
        cursor: pointer;
        border-radius: 2px;
        /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 60px 10px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
    }
    .title-company{
        padding-top: 8px;

    }

    .title-company span{
        color: #312783;
        padding-top: 4px;
        font-size: 18px;
        font-weight: 600;
    }
    .p-hover:hover {
        cursor: pointer;
        color: #312783;
        text-decoration: underline;
    }
    @media(width < 550px) {
        .card-company{
            padding: 12px 25px 13px 25px;
        }
    }
    @media(width < 440px) {
        p{
            font-size: 10px;
        }
        .top_cam {
            font-size: 15px;
        }
    }
    @media(width < 330px) {
        p{
            font-size: 8px;
        }
        .top_cam {
            font-size: 15px;
        }
    }
</style>