<template>
    <div class="choose-service">
        <div class="choose-us containers">
            <div class="title-choose-us">
                <p>{{ $t('message.whyYouChooseUs') }}<span>!</span></p>
            </div>
            <div class="title-decription">
                <p>{{ $t('message.DesWhyYouChooseUs') }}</p>
            </div>
            <div class="row" style="padding-top: 20px;">
                <div class="col-lg-4 col-md-4 col-sm-6 choose-us-icon" v-for="(data, i) in data" :index="i" :key="i">
                    <div class="icon-text">
                        <div class="icon-img ">
                            <img :src="data.icon" alt="">
                        </div>
                        <div class="text-description-about-img wid-100">
                            <p class="title">{{ $t(data.title) }}</p>
                            <p class="description">{{ $t(data.description) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: [
                {
                    icon: require('@/assets/amenity/1.svg'),
                    title: 'message.complimentary',
                    description: 'message.desComplimentary',
                },
                {
                    icon: require('@/assets/amenity/2.svg'),
                    title: 'message.liabilityInsurance',
                    description: 'message.desLiabilityInsurance',
                },
                {
                    icon: require('@/assets/amenity/3.svg'),
                    title: 'message.powerUsbCharger',
                    description: 'message.desPowerUsbCharger',
                },
                {
                    icon: require('@/assets/amenity/4.svg'),
                    title: 'message.customerService',
                    description: 'message.desCustomerService',
                },
                {
                    icon: require('@/assets/amenity/5.svg'),
                    title: 'message.airConditioned',
                    description: 'message.desAirConditioned',
                },
                {
                    icon: require('@/assets/amenity/6.svg'),
                    title: 'message.paymentTicket',
                    description: 'message.desPaymentTicket',
                },
                {
                    icon: require('@/assets/amenity/7.svg'),
                    title: 'message.comfortableSeat',
                    description: 'message.desComfortableSeat',
                },
                {
                    icon: require('@/assets/amenity/8.svg'),
                    title: 'message.gpsTrackingSystem',
                    description: 'message.desGpsTrackingSystem',
                },
                {
                    icon: require('@/assets/amenity/9.svg'),
                    title: 'message.schedule',
                    description: 'message.desSchedule',
                },
                {
                    icon: require('@/assets/amenity/10.svg'),
                    title: 'message.ourDriver',
                    description: 'message.desOurDriver',
                },
            ],
        }
    },
    watch: {

    },
    methods: {},
    created() {

    }
}
</script>

<style scoped>
.choose-service {
    background: #F2F2F2;
    padding-top: 25px;
}

.choose-us {
    width: 100%;
    padding-bottom: 50px;
}

.choose-us .title-choose-us {
    padding-top: 60px;
    width: 100%;
    text-align: center;
}

.choose-us .title-choose-us p {
    color: #1E1E1F;
    font-size: 36px;
    font-weight: 700;
    line-height: 0.70;
}

.choose-us .title-choose-us span {
    color: #DE5D0A;
}

.choose-us .title-decription {
    width: 100%;
    text-align: center;
}

.choose-us .title-decription p {
    color: #424245;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

.choose-us-icon {
    margin-top: 10px;
}

/* Amenity */
.icon-text {
    display: flex;
}

.icon-text .icon-img {
    width: 22%;
}

.icon-text .text-description-about-img {
    width: 78%;
}

.icon-text .text-description-about-img .title {
    color: #1D1D1D;
    font-weight: 600;
    font-size: 24px;
}

.icon-text .text-description-about-img .description {
    margin-top: -9px;
    color: #424245;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
}

/* Responsive */
@media (width < 1000px) {
    .icon-text {
        display: block;
        display: block;
    }

    .wid-100 {
        width: 100% !important;
    }
}

@media (width < 450px) {
    .choose-us {
        display: none;
    }
}
</style>