<template>
    <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else>
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div class="passengers" style="background: #F2F2F2" v-else>
            <HeaderComponent />
            <div class="container-fluid passenger-pd">
                <div class="containers mtop pt-20 pb-20">
                    <div class="title-booking-detail">
                        <p @click="checkName()">{{ $t('message.bookingDetail') }}</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12">
                            <div class="passDetails">
                                <div class="number">1</div>
                                <div class="title-pass">{{ $t('message.passengerDetail') }}</div>
                            </div>
                            <div class="form">
                                <div class="passenger">
                                    <div class="row hide-label-phone">
                                        <div class="col-md-5 col-sm-12">
                                            <label>{{ $t('message.fullName') }}<span style="color: #FF0000;">*</span></label>
                                        </div>
                                        <div class="col-md-4 col-sm-12">
                                            <label>{{ $t('message.nationality') }}</label>
                                        </div>
                                        <div class="col-md-3 col-sm-12">
                                            <label>{{ $t('message.gender') }} <span style="color: #FF0000;">*</span></label>
                                        </div>
                                    </div>
                                    <div style="margin-top: -23px;">
                                        <div class="row" v-for="(seat, index) in seatLabels" :key="seat">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="group-form"> 
                                                    <label for="" class="hide-label equal_label">{{ $t('message.fullName') }}<span style="color: #FF0000;">*</span></label>
                                                    <input type="text" v-model="fullname[index]" @input="autoFieldToBack(index)" class="custom-input equals_input" :placeholder=" $t('message.enterYourfullName')">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <div class="group-form">
                                                    <label for="" class="hide-label equal_label">{{ $t('message.nationality') }}</label>
                                                    <div class="equals_input">
                                                        <div style="position: relative;" v-if="checkNational">
                                                            <input type="text" disabled v-model="Noncambodian" class="custom-input">
                                                            <!-- <select class="custom-input" >
                                                                <option value="1">Non-Cambodian</option>
                                                            </select>
                                                            <i style="position: absolute;top: 34%;right: 9px;" class="fa-sharp fa-solid fa-caret-down"></i> -->
                                                        </div>
                                                        <input v-else type="text" disabled v-model="cambodian" class="custom-input">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3">
                                                <div class="group-form">
                                                    <label for="" class="hide-label equal_label">{{ $t('message.gender') }} <span style="color: #FF0000;">*</span></label>
                                                    <div class="equals_input" style="position: relative;">
                                                        <div v-if="selected[index] == 0" style="position: relative;">
                                                            <input type="text" readonly :value="$t(selectedOption)" class="custom-input" @click="selectGender(index)">
                                                            <i style="position: absolute;top: 34%;right: 9px;" @click="selectGender(index)" class="fa-sharp fa-solid fa-caret-down"></i>
                                                        </div>
                                                        <div v-if="selected[index] != 0">
                                                            <div v-if="selected[index] == 1" style="position: relative;">
                                                                <input type="text" readonly v-model="selectedOption1" class="custom-input" @click="selectGender(index)">
                                                                <i style="position: absolute;top: 34%;right: 9px;" @click="selectGender(index)"  class="fa-sharp fa-solid fa-caret-down"></i>
                                                            </div>
                                                            <div v-if="selected[index] == 2" style="position: relative;">
                                                                <input type="text" readonly v-model="selectedOption2" class="custom-input" @click="selectGender(index)">
                                                                <i style="position: absolute;top: 34%;right: 9px;" @click="selectGender(index)" class="fa-sharp fa-solid fa-caret-down"></i>
                                                            </div>
                                                        </div>
                                                        <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px;" v-if="showListDataGen" class="hideNational" :class="{showNational: index == activeItem}" v-click-outside="hidePopupGen">
                                                            <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setGender(index, 1)">{{ $t("message.male") }}</div>
                                                            <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setGender(index, 2)">{{ $t("message.Female") }}</div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="dotted"><img style="width: 100%;" src="@/assets/line.png" alt=""></div>
                                        </div>
                                    </div>
                                
                                
                                </div>
                                <div style="padding-top: 30px;">
                                    <div class="passDetails">
                                        <div class="number">2</div>
                                        <div class="title-pass">{{ $t('message.location') }}   {{getDestinationFrom()}} {{ $t('message.to') }} {{getdestinationToName()}}</div>
                                    </div>
                                    <div class="row rowScreen passenger" style="height:auto;">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            
                                            <label for="" class="equal_label">{{ $t('message.boardingPoint') }} <span style="color: #FF0000;" v-if="boardingPoint.length != 1">*</span> </label>
                                            <div variant="outlined" readonly class="custom-select" :tabindex="tabindex">
                                                <div class="selected" :class="{hideAfterdrop: boardingPoint.length == 1, open: open }" @click="open = !open">
                                                    <!-- {{ limitStr(selectedBox, 35) }} -->
                                                    <div class="mobileScreen">
                                                        {{ selectedBox == "" ? "Select your boarding point" : selectedBox }} 
                                                    </div>
                                                    <!-- <div :title="selectedBox" class="mobileScreen d-none-for-mobile">
                                                        {{ limitStr(selectedBox, 50) }}
                                                    </div> -->
                                                    
                                                </div>
                                                <div class="items" :class="{ selectHide: !open }" v-if="boardingPoint.length != 1">
                                                    <div class="header-drop-point"> {{ $t('message.boardingPoint') }}</div>
                                                    <div class="d-flex mb-3" v-for="point in boardingPoint" :key="point.id" @click="storeData(pointName = point.address, pointId= point.id),selectedBox = point.address + ' ' + convertTimes(point.time); open = false; $emit('input', point.name);">
                                                        <div class="checkbox-mobile" style="margin-left: -9px; margin-right: 30px;"> 
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" disabled :checked="selectedCheckbox == point.id" :value="point.id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="right-info-name-map"> 
                                                            <div class="d-flex justify-space-between" style="padding-bottom: 8px;">
                                                                <div class="name-width">
                                                                    <span style="color: #E38F5A; font-weight: 600;">{{ point.name }} {{ convertTimes(point.time) }}</span>
                                                                
                                                                </div>
                                                                <div class="map-point-width">
                                                                    <a @click.stop="navigateToMap(point.lats, point.longs),open = true;" style="text-decoration: underline; font-weight: 600; color: #312783;">{{ $t('message.viewMap') }}</a>
                                                                </div>
                                                            </div>
                                                            <div style="width:95%; margin-left: -12px;">
                                                                <p>{{ point.address }}</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <!-- <div  v-for="point in boardingPoint" :key="point.id" @click="storeData(pointName = point.address, pointId= point.id),selectedBox = point.address; open = false; $emit('input', point.name);">
                                                        {{ point.address }}
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                            
                                        
                                    </div>
                                    <div class="row passenger" style="margin-bottom: auto !important; padding-top: 0px !important; height:auto;">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            
                                            <label for="" class="equal_label">{{ $t('message.dropOffPoint') }} <span style="color: #FF0000;" v-if="dropOffPoint.length != 1">*</span></label>
                                            <div class="custom-select" :tabindex="tabindex">
                                                <div class="selected"  :class="{hideAfterdrop: dropOffPoint.length == 1, open1: open1 }" @click="open1 = !open1">
                                                    <!-- {{ limitStr(selectedBox, 35) }} -->
                                                    <div class="mobileScreen">
                                                        {{ selectedBoxDown == "" ? "Select your Drop Off point" : selectedBoxDown }} 
                                                    </div>
                                                    <!-- <div :title="selectedBoxDown" class="mobileScreen d-none-for-mobile" style="line-height: 35px;">
                                                        {{ limitStr(selectedBoxDown, 50) }} 
                                                    </div> -->
                                                    
                                                    
                                                </div>
                                                <div class="items" :class="{ selectHide: !open1 }" v-if="dropOffPoint.length != 1">
                                                <div class="header-drop-point"> {{ $t('message.dropOffPoint') }}t</div>
                                                <div class="d-flex mb-3" v-for="dropoff in dropOffPoint" :key="dropoff.id"  @click="storeDataDown(pointNameDown = dropoff.address, pointIdDown = dropoff.id),selectedBoxDown = dropoff.address + ' ' + convertTimes(dropoff.time); open1 = false; $emit('input', dropoff.name);">
                                                    <div class="checkbox-mobile" style="margin-left: -9px; margin-right: 30px;"> 
                                                        <label class="custom-checkbox">
                                                            <input type="checkbox" disabled :checked="selectedCheckboxDown == dropoff.id" :value="dropoff.id">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div class="right-info-name-map"> 
                                                        <div class="d-flex justify-space-between" style="padding-bottom: 8px;">
                                                            <div class="name-width">
                                                                <span style="color: #E38F5A; font-weight: 600;">{{ dropoff.name }} {{ convertTimes(dropoff.time) }}</span>
                                                            </div>
                                                            <div class="map-point-width">
                                                                <a @click.stop="navigateToMap(dropoff.lats, dropoff.longs),open1 = true;" style="text-decoration: underline; font-weight: 600; color: #312783;">{{ $t('message.viewMap') }}</a>
                                                            </div>
                                                        </div>
                                                        <div style="width:95%; margin-left: -12px;">
                                                            <p>{{ dropoff.address }}</p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                    <!-- <div  v-for="dropoff in dropOffPoint" :key="dropoff.id"  @click="storeDataDown(pointNameDown = dropoff.address, pointIdDown = dropoff.id),selectedBoxDown = dropoff.address; open1 = false; $emit('input', dropoff.name);">
                                                        {{ dropoff.address }}
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                            
                                        
                                    </div>
                                    
                                </div>
                                <div v-if="returnTrip" class="passDetails-info">
                                    <div class="passDetails passDetails-number">
                                        <div class="number">3</div>
                                        <div class="title-pass">{{ $t('message.passengerDetail') }} </div>
                                    </div>
                                    <div class="passenger">
                                        <div class="row hide-label-phone">
                                            <div class="col-md-5 col-sm-12">
                                                <label for="">{{ $t('message.fullName') }}<span style="color: #FF0000;">*</span></label>
                                            </div>
                                            <div class="col-md-4 col-sm-12">
                                                <label for="">{{ $t('message.nationality') }}</label>
                                            </div>
                                            <div class="col-md-3 col-sm-12">
                                                <label for="">{{ $t('message.gender') }} <span style="color: #FF0000;">*</span></label>
                                            </div>
                                        </div>
                                        <div style="margin-top: -23px;">
                                            <div class="row" v-for="(seat, index) in seatLabelbacks" :key="seat.id">
                                                <div class="col-lg-5 col-md-5 col-sm-5">
                                                    <div class="group-form">
                                                        <label for="" class="hide-label equal_label">{{ $t('message.fullName') }}<span style="color: #FF0000;">*</span></label>
                                                        <input type="text" v-model="fullnameback[index]" @click="myChangeFunction(index)"  class="custom-input equals_input" :placeholder="$t('message.enterYourfullName')">
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4">
                                                    
                                                    <div class="group-form">
                                                        <label for="" class="hide-label equal_label">{{ $t('message.nationality') }}</label>
                                                        <div class="equals_input">
                                                            <div style="position: relative;" v-if="checkNational">
                                                                <input type="text" disabled v-model="Noncambodian" class="custom-input">
                                                                <!-- <select class="custom-input" >
                                                                    <option value="1">Non-Cambodian</option>
                                                                </select> -->
                                                                <!-- <i style="position: absolute;top: 34%;right: 9px;" class="fa-sharp fa-solid fa-caret-down"></i> -->
                                                            </div>
                                                            <input v-else type="text" disabled v-model="cambodian" class="custom-input">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-3">
                                                    <div class="group-form">
                                                        <label for="" class="hide-label equal_label">{{ $t('message.gender') }} <span style="color: #FF0000;">*</span></label>
                                                        <div class="equals_input" style="position: relative;">
                                                            <div v-if="selectedback[index] == 0" style="position: relative;">
                                                                <input type="text" readonly :value="$t(selecteGenBack0)" class="custom-input" @click="selectGenderBack(index)">
                                                                <i style="position: absolute;top: 34%;right: 9px;" @click="selectGenderBack(index)" class="fa-sharp fa-solid fa-caret-down"></i>
                                                            </div>
                                                            <div v-if="selectedback[index] != 0">
                                                                <div v-if="selectedback[index] == 1" style="position: relative;">
                                                                    <input type="text" readonly v-model="selectedOptionBack1" class="custom-input" @click="selectGenderBack(index)">
                                                                    <i style="position: absolute;top: 34%;right: 9px;" @click="selectGenderBack(index)" class="fa-sharp fa-solid fa-caret-down"></i>
                                                                </div>
                                                                <div v-if="selectedback[index] == 2" style="position: relative;">
                                                                    <input type="text" readonly v-model="selectedOptionBack2" class="custom-input" @click="selectGenderBack(index)">
                                                                    <i style="position: absolute;top: 34%;right: 9px;" @click="selectGenderBack(index)" class="fa-sharp fa-solid fa-caret-down"></i>
                                                                </div>
                                                            </div>
                                                            <div style="position: absolute;width: 100%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 4px;" v-if="showListDataGenBack" class="hideNational" :class="{showNational: index == activeItem}" v-click-outside="hidePopupGen">
                                                                <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setGenderBack(index, 1)">{{ $t('message.male') }}</div>
                                                                <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setGenderBack(index, 2)">{{ $t('message.Female') }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dotted"><img style="width: 100%;" src="@/assets/line.png" alt=""></div>
                                            </div>
                                        </div>

                                    
                                    </div>
                                </div>
                                <div v-if="returnTrip" style="padding-top: 30px;">
                                    <div class="passDetails" style="padding-bottom: 20px;">
                                        <div class="number">4</div>
                                        <div class="title-pass">{{ $t('message.location') }} {{getdestinationToName()}} {{ $t('message.to') }} {{getDestinationFrom()}}</div>
                                    </div>
                                    <div class="row passenger" style=" margin-bottom: auto !important; padding-top: 0px !important; height:auto;">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            
                                            <label for="" class="equal_label">{{ $t('message.boardingPoint') }} <span style="color: #FF0000;" v-if="boardingPointBack.length != 1">*</span></label>
                                            <div class="custom-select" :tabindex="tabindex">
                                                <div class="selected" :class="{hideAfterdrop: boardingPointBack.length == 1, open2: open2 }" @click="open2 = !open2">
                                                    <!-- {{ limitStr(selectedBox, 35) }} -->
                                                    <div class="mobileScreen">
                                                        {{ selectedBoxBack == '' ? "Select your boarding point" : selectedBoxBack}}
                                                    </div>
                                                    <!-- <div :title="selectedBoxBack" class="mobileScreen d-none-for-mobile">
                                                        {{ limitStr(selectedBoxBack, 50) }}
                                                    </div> -->
                                                    
                                                    
                                                </div>
                                                
                                                <div class="items" :class="{ selectHide: !open2 }" v-if="boardingPointBack.length != 1">
                                                    <div class="header-drop-point"> {{ $t('message.boardingPoint') }}</div>
                                                    <div class="d-flex mb-3" v-for="pointBack in boardingPointBack" :key="pointBack.id" @click="storeDataBack(pointName = pointBack.address, pointId= pointBack.id),selectedBoxBack = pointBack.address + ' ' + convertTimes(pointBack.time); open2 = false ">
                                                        <div class="checkbox-mobile" style="margin-left: -9px; margin-right: 30px;"> 
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" disabled :checked="selectedCheckboxBack == pointBack.id" :value="pointBack.id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="right-info-name-map"> 
                                                            <div class="d-flex justify-space-between" style="padding-bottom: 8px;">
                                                                <div class="name-width">
                                                                    <span style="color: #E38F5A; font-weight: 600;">{{ pointBack.name }} {{ convertTimes(pointBack.time) }}</span>
                                                                   
                                                                </div>
                                                                <div class="map-point-width">
                                                                    <a @click.stop="navigateToMap(pointBack.lats, pointBack.longs),open2 = true;" style="text-decoration: underline; font-weight: 600; color: #312783;">{{ $t('message.viewMap') }}</a>
                                                                </div>
                                                            </div>
                                                            <div style="width:95%; margin-left: -12px;">
                                                                <p>{{ pointBack.address }}</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <!-- <div  v-for="pointBack in boardingPointBack" :key="pointBack.id" @click="storeDataBack(pointName = pointBack.address, pointId= pointBack.id),selectedBoxBack = pointBack.address; open2 = false ">
                                                        {{ pointBack.address }}
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row passenger" style="padding-top: 0px !important; margin-top: 0px !important; margin-bottom: auto !important; height:auto;">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            
                                            <label for="" class="equal_label">{{ $t('message.dropOffPoint') }} </label>
                                            <div class="custom-select" :tabindex="tabindex">
                                                <div class="selected" :class="{hideAfterdrop: dropOffPointBack.length == 1, open3: open3 }" @click="open3 = !open3">
                                                    <!-- {{ limitStr(selectedBox, 35) }} -->
                                                    <div class="mobileScreen">
                                                        {{ selectedBoxBackDown == '' ? 'Select your Drop Off point' : selectedBoxBackDown }}
                                                    </div>
                                                    <!-- <div :title="selectedBoxBackDown" class="mobileScreen d-none-for-mobile" style="line-height: 35px;">
                                                    
                                                        {{limitStr(selectedBoxBackDown,50) }}
                                                    </div> -->
                                                    
                                                    
                                                </div>
                                                
                                                <div class="items" :class="{ selectHide: !open3 }" v-if="dropOffPointBack.length != 1">
                                                    <div class="header-drop-point"> {{ $t('message.dropOffPoint') }} <span style="color: #FF0000;" v-if="dropOffBack.length != 1">*</span></div>
                                                    <div class="d-flex mb-3" v-for="dropOffBack in dropOffPointBack" :key="dropOffBack.id" @click="storeDataBackDown(pointName = dropOffBack.address, pointId= dropOffBack.id),selectedBoxBackDown = dropOffBack.address + ' ' + convertTimes(dropOffBack.time); open3 = false ">
                                                        <div class="checkbox-mobile" style="margin-left: -9px; margin-right: 30px;"> 
                                                            <label class="custom-checkbox">
                                                                <input type="checkbox" disabled :checked="selectedCheckboxBackDown == dropOffBack.id" :value="dropOffBack.id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="right-info-name-map"> 
                                                            <div class="d-flex justify-space-between" style="padding-bottom: 8px;">
                                                                <div class="name-width">
                                                                    <span style="color: #E38F5A; font-weight: 600;">{{ dropOffBack.name }} {{ convertTimes(dropOffBack.time) }}</span>
                                                                   
                                                                </div>
                                                                <div class="map-point-width">
                                                                    <a @click.stop="navigateToMap(dropOffBack.lats, dropOffBack.longs),open3 = true;" style="text-decoration: underline; font-weight: 600; color: #312783;">{{ $t('message.viewMap') }}</a>
                                                                </div>
                                                            </div>
                                                            <div style="width:95%; margin-left: -12px;">
                                                                <p>{{ dropOffBack.address }}</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <!-- <div  v-for="dropOffBack in dropOffPointBack" :key="dropOffBack.id" @click="storeDataBackDown(pointName = dropOffBack.address, pointId= dropOffBack.id),selectedBoxBackDown = dropOffBack.address; open3 = false ">
                                                        {{ dropOffBack.address }}
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="details-screen">
                                    <div class="passDetails" style="padding-bottom: 20px;">
                                        <div class="number" v-if="returnTrip">5</div>
                                        <div class="number" v-else>3</div>
                                        <div class="title-pass">{{ $t('message.billingDetail') }} </div>
                                    </div>
                                    <div class="row">
                                        <div style="margin-top: -12px; margin-left: 55px">{{ $t('message.yourBookingDetailticketWillBeSent') }}</div>
                                    </div>
                                    <div class="row passenger" style="padding-top: 3px !important;">
                                        <div class="col-lg-7 col-md-6 col-sm-6">
                                            <div class="group-form1">
                                                <label for="email">{{ $t('message.email') }} <span style="color: #FF0000;">*</span></label>
                                                <input type="email" v-model="email" class="custom-input" :placeholder="$t('message.emailAddress')">
                                                <span v-if="msg.email" style="color: #FF0000;">{{ msg.email }}</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-6 col-sm-6">
                                            <div class="group-form1">
                                                <label for="">{{ $t('message.telephoneNumber') }}  <span style="color: #FF0000;" v-if="isUseLuckyDraw==1">*</span></label>
                                                <input type="tel" v-model="phone" @input="validatePhoneNumber" class="custom-input" :placeholder="$t('message.enterTelephoneNumber')">
                                                <span v-if="phoneNumberError.phone" style="color: #FF0000;">{{ phoneNumberError.phone }}</span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                                <div class="row" style="margin-top: 14px;">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="noted-rq">
                                            <div class="img-note">
                                                <div class="bg-lights">
                                                    <img src="@/assets/light.png" alt="">
                                                </div>
                                                <div style="font-size: 16px;font-weight: 600;color: #424245;">{{ $t('message.noted') }} *</div>
                                            </div>
                                            <div class="noted-text">
                                                <div style="font-weight: bold;" class="pb-2">{{ $t('message.attention') }} !</div>
                                                <span>{{ $t('message.desAttention') }}</span>
                                                <div style="font-weight: bold;" class="pb-2 pt-2">{{ $t('message.boardingPoint') }}</div>
                                                <span>{{ $t('message.desBoardingPointNight') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-12 padd-lefe">
                            <!-- information seat -->
                            <div class="row" style="background: #FFFFFF !important; border-radius: 8px; opacity: 1; z-index: 100;">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="fare-detail ml-3">
                                        {{ $t('message.fareDetail') }}
                                    </div>
                                    <div class="data-details ml-3 mt-2">
                                        <div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.direction') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{getDestinationFrom()}} {{ $t('message.to') }} {{getdestinationToName()}}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.departureDate') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{getDateFrom()}}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.departureTime') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{convertTimes(getDeparture())}}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.arrivalTime') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{convertTimes(getArrvalDate())}}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.selectedSeat') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ getSeat() }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.seatNumber') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{getSeatLabel()}}
                                                </div>
                                            </div>
                                            <div v-if="returnTrip">
                                                <div v-if="priceOriginal">
                                                
                                                </div>
                                                <div v-else>
                                                    <div class="d-flexs">
                                                        <div class="static">
                                                            {{ $t('message.grandTotal') }}:
                                                        </div>
                                                        <div class="dynamic">
                                                            ${{formatPrice(grandTotal())}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="d-flexs">
                                                    <div class="static">
                                                        {{ $t('message.grandTotal') }}:
                                                    </div>
                                                    <div class="dynamic">
                                                        ${{formatPrice(grandTotal())}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div  v-if="returnTrip">
                                            <div class="dotteded" style="margin-bottom: -8px !important;"><img style="width: 100%;" src="@/assets/Line1.png" alt=""></div>
                                            <div class="d-flexs">
                                            <div class="static">
                                                {{ $t('message.direction') }}:
                                            </div>
                                            <div class="dynamic">
                                                {{getdestinationToName()}} {{ $t('message.to') }} {{getDestinationFrom()}}
                                            </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.departureDate') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{getDateTo()}}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.departureTime') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{getDepartureBack()}}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.arrivalTime') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{getArrvalDateBack()}}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.selectedSeat') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{ seatNumBack }}
                                                </div>
                                            </div>
                                            <div class="d-flexs">
                                                <div class="static">
                                                    {{ $t('message.seatNumber') }}:
                                                </div>
                                                <div class="dynamic">
                                                    {{seatLabelBack}}
                                                </div>
                                            </div>
                                            <div class="d-flexs" v-if="!showpriceOriginalback">
                                                <div class="static">
                                                    {{ $t('message.grandTotal') }}:
                                                </div>
                                                <div class="dynamic">
                                                    ${{formatPrice(grandTotalBack())}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <!-- total calulation seat -->
                            <div class="row mt-8" style="background: #FFFFFF !important; border-radius: 8px; opacity: 1; z-index: 100;">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="fare-detail ml-3">
                                        {{ $t('message.totalSummary') }}
                                    </div>
                                    <div class="data-details mt-2">
                                        <div>
                                            <!-- <div class="d-flexs">
                                                <div class="static">
                                                    Lucky Draw:
                                                </div>
                                                <div class="dynamic" style="text-align: end;">
                                                    {{ handleCheckboxChangeluckydraw() != 0 ?   "$" + formatPrice(handleCheckboxChangeluckydraw()) : '$-' }}
                                                </div>
                                            </div> -->
                                            <div style="background: #F1AF88;" v-if="isLuckyDrawEnabled">
                                                <div class="check-box" style="justify-content: center;">
                                                    <div class="text d-flex" style="color: #FFFFFF; font-size: 14px; width:92%;">                                          
                                                        {{ $t('message.luckyTicketTitle') }}
                                                    </div>
                                                </div>
                                                <div class="check-box" style="justify-content: center;">
                                                    <div class="text d-flex" style="color: #FFFFFF; width:92%; padding-bottom: 10px;">
                                                        <div style="margin-top: -10px; margin-left: 2px">
                                                            <v-checkbox color="#DE5D0A" style="transform: scale(1.5);" v-model="isCheckedBox" @change="handleCheckboxChangeluckydraw"></v-checkbox>
                                                        </div>
                                                        <div style="font-size: 14px;">
                                                            <!-- If you check the <a target="_blank" href="https://www.facebook.com/VirakBunthamExpress" style="color: #DE5D0A;">LUCKY DRAW BUTTON </a> You will charge $0.25. You See Lucky draw on FB Page. -->
                                                            <div>
                                                                {{ $t('message.desLuckyTicket') }}
                                                            </div>
                                                            <div class="mt-3">
                                                                {{ $t('message.subDesLuckyTicketAdd') }} <span  style="font-weight: bold; color: #FFFFFF;">{{ $t('message.desLuckyTicketSpanBlod') }} </span>   {{ $t('message.subDesLuckyTicket') }} <a target="_blank" href="https://www.facebook.com/VirakBunthamExpress" style="text-decoration: underline; color: #FFFFFF;">{{ $t('message.desLuckyTicketSpan') }}</a>   {{ $t('message.subDesLuckyTicket1') }}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ml-3 mt-3">
                                                <!-- <div class="d-flexs">
                                                    <div class="static" style="color: #424245;">
                                                        Apply Coupon Code
                                                    </div>
                                                </div> 
                                                <div class="d-flex align-center justify-space-between mt-3">
                                                    <div style="width: 70%;">
                                                        <div class="group-form1">
                                                            <input type="text" style="border: 1px solid #C6C6C6;" class="custom-input" placeholder="Enter your code">
                                                        </div>
                                                        
                                                    </div>
                                                    <div style="width: 26%;">
                                                        <div class="apply">
                                                            <div>Apply</div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="d-flexs mt-5">
                                                    <div class="static">
                                                        {{ $t('message.grandTotal') }}:
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;">
                                                        <!-- ${{ parseFloat(this.grandTotal()) + parseFloat(this.grandTotalBack()) }} -->
                                                        ${{ formatPrice(calculateGrandTotal()) }}
                                                    </div>
                                                </div>
                                                <div class="d-flexs mt-3">
                                                    <div class="static">
                                                        {{ $t('message.discount') }} ({{ disPercent ? disPercent :'0' }}%):
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;" v-if="returnTrip" >
                                                       
                                                        ${{ formatPrice(parseFloat(disConut()) + parseFloat(disConutBack())) }}
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;" v-else >
                                                       
                                                       ${{ formatPrice(disConut()) }}
                                                   </div>
                                                </div>
                                                <div class="d-flexs mt-3" v-if="handleCheckboxChangeluckydraw() != 0 ">
                                                    <div class="static">
                                                        {{ $t('message.luckyTicket') }}:
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;"  >
                                                       ${{  formatPrice(handleCheckboxChangeluckydraw()) }}
                                                       
                                                    </div>
                                                   
                                                </div>
                                                <div class="d-flexs mt-3" v-if="returnTrip">
                                                    <div class="static">
                                                        {{ $t('message.total') }}:
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;">
                                                        ${{formatPrice(priceDisTotalBack())}}
                                                    </div>
                                                </div>
                                                <div class="d-flexs mt-3" v-else>
                                                    <div class="static">
                                                        {{ $t('message.total') }}:
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;" v-if="isCheckedBox">
                                                        ${{formatPrice(priceDisTotalHaveLuckyDraw())}}
                                                    </div>
                                                    <div class="dynamic" style="text-align: end;" v-else>
                                                        ${{formatPrice(formatPrice(priceDisTotal()))}}
                                                    </div>
                                                </div>
                                                <div class="check-box">
                                                <!-- <v-checkbox v-model="checkbox"></v-checkbox> -->
                                                <div class="text" style="color: #424245;">
                                                    {{ $t('message.desTermCoditionPrivacyPolicy') }} <a href="/term-condition" target="_blank" style="color: #312783;">{{ $t('message.termCondition') }} </a> {{ $t('message.desTermCoditionPrivacyPolicyAnd') }} <a href="/private-policy"  target="_blank" style="color: #312783;">{{ $t('message.privacyPolicy') }}</a></div>
                                                </div>
                                                <!-- v-if="checkbox" -->
                                                <div class="proccess">
                                                    <div v-if="returnTrip" @click="processPaymentTwo">{{ $t('message.processPayment') }}</div>
                                                    <div v-else @click="processPayment">{{ $t('message.processPayment') }}</div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                 
                                </div>
                                
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <IncFooter />
        </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import axios from 'axios';
import qs from 'qs';
export default {
    components: { HeaderComponent, IncFooter },
    props: {
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            loading: true,
            validateField: false,
            fullname: [],
            fullnameback: [],
            index: 0,
            selectedOption: 'message.gender',
            selectedOption1: "",
            selectedOption2: "",
            selecteGenBack0: 'message.gender',
            selectedOptionBack1: "",
            selectedOptionBack2: "",
            phone: "",
            phoneNumberError: [],
            maxLength: 9, // maximum allowed phone number length
            maxLengths: 10, // maximum allowed phone number length
            email: "",
            msg: [],
            isValidEmail: true,
            seatNum: [],
            seatNumBack: [],
            seatLabelBack: '',
            total: 0,
            returnTrip: false,
            checkbox: false,
            seatLabels: [],
            seatLabelbacks: [],
            checkNational: true,
            radioGroup: 1,
            Noncambodian: this.$t('message.nonCambodian') ,
            cambodian: this.$t('message.cambodian'),
            // showDiscount: false,
            // showDiscountBack: false,
            showpriceOriginalback: false,
            priceOriginal: false,
            discount: 5,
            selected: [],
            selectedback: [],
            selectedname: [],
            activeItem: 0,
            showListDataGen: false,
            showListDataGenBack: false,
            check: false,
            isCheckedBox: false,
            isUseLuckyDraw:'',

            boardingPoint:[],
            dropOffPoint:[],
            boardingPointBack:[],
            dropOffPointBack:[],
            
            selectedBoxId: "",
            selectedBox: "",
            selectedBoxIdBack: "",
            selectedBoxBack: "",
            selectedBoxIdBackDown: "",
            selectedBoxBackDown: "",

            selectedBoxDownId: "",
            selectedBoxDown: "",

            selectedCheckbox: null,
            selectedCheckboxDown: null,
            selectedCheckboxBack: null,
            selectedCheckboxBackDown:null,

            pointName: "",
            pointId: "",
            pointNameDown: "",
            pointIdDown: "",
            pointNameBack: "",
            pointIdBack: "",
            pointNameBackDown: "",
            pointIdBackDown: "",
            
            disPercent: localStorage.getItem('disPercent'),
            disPercentBack: localStorage.getItem('disPercentBack'),


            open: false,
            open1: false,
            open2: false,
            open3: false,
        }
    },
    watch: {
        email(value) {
            // binding this to the data value in the email input
            this.email = value;
            this.validateEmail(value);
        },
        phone(value) {
            this.phone = value;
            this.validatePhoneNumber(value);
        },
    },
    computed: {
        isLuckyDrawEnabled() {
        const cutoffDate = new Date('2024-12-31');
        const journeyDate = new Date(localStorage.getItem('dateFrom'));
        const journeyDateBack = localStorage.getItem('dateTo') ? new Date(localStorage.getItem('dateTo')) : null;
        
        // Check if either journey date is after cutoff
        if (journeyDate > cutoffDate) return false;
        if (journeyDateBack && journeyDateBack > cutoffDate) return false;
        
        return true;
    }  
    },
   
    methods: {
        
        storeData(pointName, pointId) {
            this.pointName = pointName;
            this.pointId = pointId;
            if (pointId == '') {
                this.selectedCheckbox = null;
            } else if(this.selectedCheckbox == pointId){
                this.selectedCheckbox = pointId;
            }else{
                this.selectedCheckbox = pointId;
            }
            // console.log(this.pointId);
            localStorage.setItem("boardingpointAddress", this.pointName);
            localStorage.setItem('boardingPointId', this.pointId);
        },
        storeDataDown(pointNameDown, pointIdDown) {
            this.pointNameDown = pointNameDown;
            this.pointIdDown = pointIdDown;
            if (pointIdDown == '') {
                this.selectedCheckboxDown = null;
            } else if(this.selectedCheckboxDown == pointIdDown){
                this.selectedCheckboxDown = pointIdDown;
            }else{
                this.selectedCheckboxDown = pointIdDown;
            }
            localStorage.setItem("dropOffPointName", this.pointNameDown);
            localStorage.setItem('dropOffPointId', this.pointIdDown);
        },
        storeDataBack(pointNameBack, pointIdBack) {
            this.pointNameBack = pointNameBack;
            this.pointIdBack = pointIdBack;
            if (pointIdBack == '') {
                this.selectedCheckboxBack = null;
            } else if(this.selectedCheckboxBack == pointIdBack){
                this.selectedCheckboxBack = pointIdBack;
            }else{
                this.selectedCheckboxBack = pointIdBack;
            }
            localStorage.setItem("boardingPointNameBack", this.pointNameBack);
            localStorage.setItem('boardingPointIdBack', this.pointIdBack);
        },
        storeDataBackDown(pointNameBackDown, pointIdBackDown) {
            this.pointNameBackDown = pointNameBackDown;
            this.pointIdBackDown = pointIdBackDown;
            if (pointIdBackDown == '') {
                this.selectedCheckboxBackDown = null;
            } else if(this.selectedCheckboxBackDown == pointIdBackDown){
                this.selectedCheckboxBackDown = pointIdBackDown;
            }else{
                this.selectedCheckboxBackDown = pointIdBackDown;
            }
            localStorage.setItem("dropOffPointNameBack", this.pointNameBackDown);
            localStorage.setItem('dropOffPointIdBack', this.pointIdBackDown);
        },

        navigateToMap(lats, longs) {
            const url = `https://maps.google.com/maps?q=${lats},${longs}`;
            window.open(url, '_blank');
        },
        autoFieldToBack(index) {
            if(this.fullname[index] !== ""){
                this.fullnameback[index] = this.fullname[index];
            }
        },
        
        myChangeFunction(index) {
            if (this.fullnameback[index] !== "") {
                this.fullnameback[index] = "";
            }
        },

        
       
        // Check roundTirp
        getCheck() {
            if(localStorage.getItem('dateTo') != null){
                this.returnTrip = true;
            }else{
                this.returnTrip = false;
            }
        },  
        // Start Get Data
        getDestinationFrom() { return localStorage.getItem('departureFromName'); },
        getdestinationToName() { return localStorage.getItem('destinationToName');},
        getDateFrom() { return localStorage.getItem('dateFrom');},
        getDateTo() { return localStorage.getItem('dateTo');},  
        getDeparture() { return localStorage.getItem('departure');},
        getDepartureBack() { return localStorage.getItem('departureback');},  
        getArrvalDate() { return localStorage.getItem('arrival');},
        getArrvalDateBack() { return localStorage.getItem('arrivalback');},
        convertTimes(time) {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}`;
        },

        getSeat() {
            if(localStorage.getItem('seat') != '') {
                let seatNum = localStorage.getItem('seat');
                let seatNumArray = seatNum.split(",");
                return this.seatNum = seatNumArray.length;
            }
        },
       
        getSeatLabel() {
            if(localStorage.getItem('seatlabel') != '') {
                let size = localStorage.getItem('seatlabel');
                let seatArray = size.split(",");
                let lblSeat = "";
                for (let i = 0; i < seatArray.length; i++) {
                    if (seatArray[i] != ",") {
                        if(i > 0){
                            lblSeat += ", ";
                        }
                        lblSeat += seatArray[i];
                    }
                }
                return this.seatLabel = lblSeat;
            }
        },

        getSeatBack() {
            if(localStorage.getItem('seatback') != '') {
                let seatNumBack = localStorage.getItem('seatback');
                let seatNumBackArray = seatNumBack.split(",");
                return this.seatNumBack = seatNumBackArray.length;
            }
        },

        getSeatLabelBack() {
            if(localStorage.getItem('seatlabelback') != '') {
                let size = localStorage.getItem('seatlabelback');
                let seatArray = size.split(",");
                let lblSeat = "";
                for (let i = 0; i < seatArray.length; i++) {
                    if (seatArray[i] != ",") {
                        if(i > 0){
                            lblSeat += ", ";
                        }
                        lblSeat += seatArray[i];
                    }
                }
                return this.seatLabelBack = lblSeat;
            }
        },
       
        // Start One way 

            // Grand Total both Period and auto discount(5%)
            grandTotal() {
                if(localStorage.getItem('priceOriginal') == "" || localStorage.getItem('priceOriginal') == null) {
                    this.priceOriginal = false;
                }else{
                    this.priceOriginal = true;
                }
                return localStorage.getItem('resultTotal');
            },

            // Total both Period and auto discount(5%)
            priceDisTotal() {
                // if(localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) {
                    return localStorage.getItem('resultTotal') - this.disConut();
                // }
                // else{
                //     return localStorage.getItem('resultTotal') ;
                // }
            },
            priceDisTotalHaveLuckyDraw() {
                // if(localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) {
                    return localStorage.getItem('resultTotal') - this.disConut() + parseFloat(this.handleCheckboxChangeluckydraw());
                // }
                // else{
                //     return localStorage.getItem('resultTotal') + parseFloat(this.handleCheckboxChangeluckydraw()) ;
                // }
            },
            // discount(5%)
            disConut() {
                let totals = parseFloat(localStorage.getItem('discount')) * parseInt(this.getSeat());
                return totals;
            },
        // End One way 

        // Start Two way 

            // Grand Total both Period and auto discount(5%)
            grandTotalBack() {
                if(localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null ) {
                    this.showpriceOriginalback = false;
                } else {
                    this.showpriceOriginalback = true;
                }
                return localStorage.getItem('resultTotalBack');
            },

            // Total both Period and auto discount(5%)
            priceDisTotalBack() {
                if((localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null) && (localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null)){
                    let totals = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack')) - this.totalDiscosunt() + parseFloat(this.handleCheckboxChangeluckydraw());
                    return totals;
                }else if((localStorage.getItem("priceOriginalback") != "" || localStorage.getItem("priceOriginalback") != null) && (localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null)){
                    let totals = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack')) - this.disConut() + parseFloat(this.handleCheckboxChangeluckydraw());
                    return totals;
                }else if((localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null) && (localStorage.getItem("priceOriginal") != "" || localStorage.getItem("priceOriginal") != null)){
                    let totals = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack')) - this.disConutBack() + parseFloat(this.handleCheckboxChangeluckydraw());
                    return totals;
                }else{
                    return parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack')) - this.totalDiscosunt() + parseFloat(this.handleCheckboxChangeluckydraw());
                }
            },

            grandTotalBackSubDis() {
                if(localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null ) {
                    return localStorage.getItem('resultTotalBack') - this.disConutBack();
                }else{
                    return localStorage.getItem('resultTotalBack');  
                }
            },


            disConutBack() {
                let disConutBack = parseFloat(localStorage.getItem('discountBack')) * parseInt(this.getSeatBack());
                return disConutBack;
            },

            totalDiscosunt() {
                let totalDiscount = parseFloat(this.disConut()) + parseFloat(this.disConutBack());
                // let calu = totalDiscount * this.discount/100;
                return totalDiscount;
            },

            totalReture() {
                let totals = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                return this.total = totals;
            },
        // End Two way 
        
        formatPrice(value) {
            let val = (value/1).toFixed(2);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        /* ------- Start One Way -----*/
        getSeatLabelRetureBack() {
            if(localStorage.getItem('seatlabelback') != null) {

                let size = localStorage.getItem('seatlabel');
                let seatArray = size.split(",");
                for (let i = 0; i < seatArray.length; i++) {
                    if (seatArray[i] != ",") {
                        this.seatLabels.push(seatArray[i]);
                        this.selected.push(0);
                    }
                }

                // Back
                let sizeLabelBack = localStorage.getItem('seatlabelback');
                let seatLabelArray = sizeLabelBack.split(",");
                for(let i = 0 ; i < seatLabelArray.length; i++) {
                    if(seatLabelArray[i] != ',') {
                        this.seatLabelbacks.push(seatLabelArray[i]);
                        this.selectedback.push(0);
                        this.selectedname.push(0);
                    }
                }

                this.getSeatBack();
                this.getSeatLabelBack();
                this.totalReture();
            }else{
                let size = localStorage.getItem('seatlabel');
                let seatArray = size.split(",");
                for (let i = 0; i < seatArray.length; i++) {
                    if (seatArray[i] != ",") {
                        this.seatLabels.push(seatArray[i]);
                        this.selected.push(0);
                    }
                }
            }
        },
        calculateGrandTotal(){
          if(this.grandTotalBack() == null || this.grandTotalBack() == ""){
            return (parseFloat(this.grandTotal()));
           
          }else{
            return (parseFloat(this.grandTotal()) + parseFloat(this.grandTotalBack())) ;
          }
          
        },
       
        showPopupGen() {
            this.showListDataGen = true;
            this.showListDataGenBack = true;
        },
        hidePopupGen() {
            this.showListDataGen = false;
            this.showListDataGenBack = false;
        },

        /*--------- Start One Way Boarding Point -------*/
        async getBoardingPoint(bId) {
            const url = this.$url + `boarding-point/findBySchedule/${bId}`;
            let config = {
                method: "POST",
                headers: this.$header,
                url
            };
            this.loading = true;
            axios(config).then(response => {
                this.loading = false;
                if(response.status == 200){
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.boardingPoint = response.data.body;
                        // let boardingPointId = [];
                        // console.log(response.data.body.length);
                       
                        if(response.data.body.length == 1){
                            for(let i = 0; i < this.boardingPoint.length; i++){
                                this.selectedBoxId = this.boardingPoint[0].id;
                                this.selectedBox = this.boardingPoint[0].address + "  " + this.convertTimes(this.boardingPoint[0].time);
                            }
                            localStorage.setItem('boardingPointId', this.selectedBoxId);
                            localStorage.setItem("boardingpointAddress", this.selectedBox);
                        }else{
                            this.selectedBoxId = "";
                            this.selectedBox = "";
                            localStorage.setItem('boardingPointId', this.selectedBoxId);
                            localStorage.setItem("boardingpointAddress", this.selectedBox);
                           
                        }
                        
                    }else{
                        this.errored = true
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },
        async getDropOffPoint(dId) {
            const url = this.$url +`drop-off-point/findBySchedule/${dId}`;
            let config = {
                method: "POST",
                headers: this.$header,
                url
            };
            axios(config).then(response => {
                if(response.status == 200){
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.dropOffPoint = response.data.body;
                        
                        // let dropOffPointId = response.data.body;
                        if(response.data.body.length == 1){
                            for(let i = 0; i <this.dropOffPoint.length; i++) {
                                this.selectedBoxDownId = this.dropOffPoint[0].id;
                                this.selectedBoxDown = this.dropOffPoint[0].address + "  " + this.convertTimes(this.dropOffPoint[0].time);
                            }
                            localStorage.setItem('dropOffPointId', this.selectedBoxDownId);
                            localStorage.setItem('dropOffPointName', this.selectedBoxDown);
                        }else{
                            this.selectedBoxDownId = '';
                            this.selectedBoxDown = '';
                            localStorage.setItem('dropOffPointId', this.selectedBoxDownId);
                            localStorage.setItem('dropOffPointName', this.selectedBoxDown);
                        }
                        
                    }else{
                        this.errored = true
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },

        selectGender(index) {
            this.activeItem = index;
            this.showListDataGen = true;
        },
        selectGenderBack(index) {
            this.activeItem = index;
            this.showListDataGenBack = true;
        },
        setGender(index, value) {
            this.selected.splice(index, 1, value);
            localStorage.setItem('gender', this.selected);
            if(this.selected[index] == 0) {
                this.selecteGen0 = 'message.gender';
            }else if(this.selected[index] == 1){
                this.selectedOption1 = this.$t("message.male");
                this.setGenderBack(index, value);
            }else{
                this.selectedOption2 = this.$t("message.Female");
                this.setGenderBack(index, value);
            }
            this.showListDataGen = false;
        },
        setGenderBack(index, value) {
            this.selectedback.splice(index, 1, value);
            localStorage.setItem('genderback', this.selectedback);
            if(this.selectedback[index] == 0) {
                this.selecteGenBack0 = 'message.gender';
            }else if(this.selectedback[index] == 1){
                this.selectedOptionBack1 = this.$t("message.male");
            }else{
                this.selectedOptionBack2 = this.$t("message.Female");
            }
            this.showListDataGenBack = false;
        },
        getGender() {
            let check = true;
            for(let i = 0; i< this.selected.length; i++) {
                if(this.selected[i] == 0){
                    check = false;
                    break;
                }
            }
            return check;
        },
        
        checkName() {
            let name = [];
            let seatName = this.fullname;
            for (let i = 0; i<seatName.length; i++){
                name.push(seatName[i]);
                if(name == '' || name == undefined) {
                    this.check = false;
                    break;
                }else{
                    return this.check = true;
                }
            }  
        },

        checkNameBack() {
            let name = [];
            let seatName = this.fullnameback;
            for (let i = 0; i<seatName.length; i++){
                name.push(seatName[i]);
                if(name == '' || name == undefined) {
                    this.check = false;
                    break;
                }else{
                    return this.check = true;
                }
            }
        },

        getGenderBack() {
            let check = true;
            for(let i = 0; i< this.selectedback.length; i++) {
                if(this.selectedback[i] == 0){
                    check = false;
                    break;
                }
            }
            return check;
        },

        // checkbox luckydraw
        handleCheckboxChangeluckydraw(){
            // console.log(this.isCheckedBox);
            let totalluckydraw;
            if(this.isCheckedBox){
                totalluckydraw = (this.seatNumBack  + this.getSeat()) * 0.25;
                this.isUseLuckyDraw = 1;
                // console.log(this.seatNumBack  + this.getSeat());
            }else{
                totalluckydraw = 0;
                this.isUseLuckyDraw = 0;
            }
            localStorage.setItem('totalluckydraw',totalluckydraw);
          
            return totalluckydraw;
            
        },
       
        processPayment() {
            if(this.seatLabels.length == this.fullname.length && this.checkName()){
                if(!this.getGender() && this.email == "" && this.phone == "") {
                    // this.validateField = true;
                    alert("Please input Email or Select Gender.");
                }else if(this.email == "" && this.phone == "" && this.getGender()){
                    alert("Please input Email.");
                } else if(this.isUseLuckyDraw == 1 && this.phone == ""){
                    // console.log(this.isUseLuckyDraw);
                    alert("Please input telephone number");
                }else if(localStorage.getItem('boardingPointId') == ''){
                    alert("Please Select boarding point");
                }else if(localStorage.getItem('dropOffPointId') == ''){
                    alert("Please Select Drop Off point");
                }
                else if(this.phone == "" && this.email != "" && this.getGender()) {
                    // email
                    let emails = "";
                    if (/^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)) {
                        emails = this.email;
                        localStorage.setItem("email", emails);
                        this.msg['email'] = '';

                        // boarding point
                        let boardingPointId = localStorage.getItem('boardingPointId');
                        let dropOffPointId = localStorage.getItem('dropOffPointId');

                        // Jouney date
                        let resultDate = [];
                        let jouDate = localStorage.getItem('dateFrom');
                        resultDate.push(jouDate);

                        // journeyId 
                        let resultJouId = [];
                        let journeyId = localStorage.getItem('journeyid');
                        resultJouId.push(journeyId);

                        // journeyType
                        let journeytype = localStorage.getItem('types');

                        // nationally
                        let nationally = localStorage.getItem("selectNational");

                        // seatGender 
                        let seaGen = [];
                        let seatGender = localStorage.getItem('gender');
                        let seatGenderArray = seatGender.split(",");
                        for(let i = 0; i<seatGenderArray.length; i++) {
                            if(seatGenderArray[i] !=",") {
                                seaGen.push(seatGenderArray[i]);
                            }
                        }
                    
                        // seatJourney
                        let seatJou = [];
                        let seatJourney = localStorage.getItem('seat')
                        console.log("Retrieved seatJourney from localStorage:", seatJourney);
                        let seatJourneyArray = seatJourney.split(",");
                        for(let i = 0; i<seatJourneyArray.length; i++) {
                            if(seatJourneyArray[i] !=",") {
                                seatJou.push(journeyId);
                            }
                        }

                        // seatName
                        let seatN = [];
                        let seatName = this.fullname;
                        for (let i = 0; i<seatName.length; i++){
                            seatN.push(seatName[i])
                        }

                        // seatNum
                        let seatNu = [];
                        let seatNum = localStorage.getItem('seat');
                        let seatNumArray = seatNum.split(",");
                        for(let i = 0; i<seatNumArray.length; i++) {
                            if(seatNumArray[i] !=",") {
                                seatNu.push(seatNumArray[i]);
                            }
                        }
                    
                        // seatPrice 
                        let resultPrice = [];
                        let SPrice = localStorage.getItem('seat');
                        let SpriceArray = SPrice.split(",");
                        for (let i = 0; i < SpriceArray.length; i++) {
                            if (SpriceArray[i] != ",") {
                                resultPrice.push(localStorage.getItem('price'));
                            }
                        }

                        let seatNumTotalSeat = localStorage.getItem('seat');
                        let seatNumArrayTotalSeat = seatNumTotalSeat.split(",");
                        let totalSeat = seatNumArrayTotalSeat.length;

                        let seatP = localStorage.getItem('price');
                        let totalAmount = totalSeat * seatP;
                        parseFloat(totalAmount);
                        
                        // totalDiscount
                        let totalDiscount = parseFloat(localStorage.getItem('discount')) * parseInt(totalSeat);
                        parseFloat(totalDiscount);
                        // let totalDiscount = 0;
                        // if(localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) {
                        //     totalDiscount = localStorage.getItem('resultTotal') * this.discount / 100;
                        //     parseFloat(totalDiscount);
                        // }else{
                        //     totalDiscount = 0;
                        //     parseFloat(totalDiscount);
                        // }
                
                        // Phone
                        localStorage.setItem("phone", this.phone);

                        const data = {
                            'boardingPointId':  parseInt(boardingPointId),
                            'dropOffId'      :  parseInt(dropOffPointId),
                            'email'          :  emails,
                            'journeyDate'    :  resultDate,
                            'journeyId'      :  resultJouId,
                            'journeyType'    :  parseInt(journeytype),
                            'nationally'     :  nationally,
                            'seatGender'     :  seaGen,
                            'seatJourney'    :  seatJou,
                            'seatName'       :  seatN,
                            'seatNum'        :  seatNu,
                            'seatPrice'      :  resultPrice,
                            'telephone'      :  this.phone,
                            'isUseLuckyDraw' : parseInt(this.isUseLuckyDraw),
                            'totalAmount'    :  totalAmount,
                            'totalDiscount'  :  totalDiscount.toFixed(2),
                            'totalSeat'      :  String(totalSeat),
                        }
                        const url = this.$url + `booking/confirm`;
                        let config = {
                            method: "POST",
                            headers: this.$header,
                            data: qs.stringify(data),
                            url
                        };
                        this.loading = true;
                        axios(config).then((response) => {
                            if (response.status == 200) {
                                this.loading = true;
                                if(response.data.header.result == true && response.data.header.statusCode == 200) {
                                    if(response.data.body.status == 1) {
                                        setTimeout(() => {
                                            this.loading = false;
                                            this.info = response.data.body;
                                            this.transactionId = this.info.transactionId;
                                            this.$router.push({
                                                path: "/payment-cart",
                                                query: {
                                                    transactionId: this.info.transactionId
                                                }
                                            });
                                        }, 1000);
                                    }else{
                                        if(response.data.body.msg == "Sorry, some seats you selected are not available."){
                                            alert("Seat is not available, please chose your trip again.");
                                            this.$router.push("/");
                                        }else{
                                            alert("Something went wrong, Please try it again.");
                                            this.$router.push("/");
                                        }
                                    }
                                }else{  
                                    this.$router.go();
                                }
                            }
                        }).catch((error) => {
                            console.log(error);
                        }); 
                    } else{
                        this.msg['email'] = 'Please enter a valid email address';
                        alert("Please enter a valid email address");
                    }
                }
               
                else if(this.email != "" && this.phone != "" && this.getGender()) {
                    const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/;
                    if(regex.test(this.phone) && /^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)) {
                        if((this.phone.length == this.maxLength) || (this.phone.length == this.maxLengths)) {
                            // boarding point
                            let boardingPointId = localStorage.getItem('boardingPointId');
                            let dropOffPointId = localStorage.getItem('dropOffPointId');

                            // Jouney date
                            let resultDate = [];
                            let jouDate = localStorage.getItem('dateFrom');
                            resultDate.push(jouDate);

                            // journeyId 
                            let resultJouId = [];
                            let journeyId = localStorage.getItem('journeyid');
                            resultJouId.push(journeyId);

                            // journeyType
                            let journeytype = localStorage.getItem('types');

                            // nationally
                            let nationally = localStorage.getItem("selectNational");

                            // seatGender 
                            let seaGen = [];
                            let seatGender = localStorage.getItem('gender');
                            let seatGenderArray = seatGender.split(",");
                            for(let i = 0; i<seatGenderArray.length; i++) {
                                if(seatGenderArray[i] !=",") {
                                    seaGen.push(seatGenderArray[i]);
                                }
                            }
                                
                            // seatJourney
                            let seatJou = [];
                            let seatJourney = localStorage.getItem('seat')
                            let seatJourneyArray = seatJourney.split(",");
                            for(let i = 0; i<seatJourneyArray.length; i++) {
                                if(seatJourneyArray[i] !=",") {
                                    seatJou.push(journeyId);
                                }
                            }

                            // seatName
                            let seatN = [];
                            let seatName = this.fullname;
                            for (let i = 0; i<seatName.length; i++){
                                seatN.push(seatName[i])
                            }

                            // seatNum
                            let seatNu = [];
                            let seatNum = localStorage.getItem('seat');
                            let seatNumArray = seatNum.split(",");
                            for(let i = 0; i<seatNumArray.length; i++) {
                                if(seatNumArray[i] !=",") {
                                    seatNu.push(seatNumArray[i]);
                                }
                            }
                        
                            // seatPrice 
                            let resultPrice = [];
                            let SPrice = localStorage.getItem('seat');
                            let SpriceArray = SPrice.split(",");
                            for (let i = 0; i < SpriceArray.length; i++) {
                                if (SpriceArray[i] != ",") {
                                    resultPrice.push(localStorage.getItem('price'));
                                }
                            }

                            let seatNumTotalSeat = localStorage.getItem('seat');
                            let seatNumArrayTotalSeat = seatNumTotalSeat.split(",");
                            let totalSeat = seatNumArrayTotalSeat.length;

                            let seatP = localStorage.getItem('price');
                            let totalAmount = totalSeat * seatP;
                            parseFloat(totalAmount);
                            
                            // totalDiscount
                            let totalDiscount = parseFloat(localStorage.getItem('discount')) * parseInt(totalSeat);
                            parseFloat(totalDiscount);
                            // let totalDiscount = 0;
                            // if(localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) {
                            //     totalDiscount = localStorage.getItem('resultTotal') * this.discount / 100;
                            //     parseFloat(totalDiscount);
                            // }else{
                            //     totalDiscount = 0;
                            //     parseFloat(totalDiscount);
                            // }
                    
                            // Phone
                            localStorage.setItem("phone", this.phone);
                            localStorage.setItem("email", this.email);

                            const data = {
                                'boardingPointId':  parseInt(boardingPointId),
                                'dropOffId'      :  parseInt(dropOffPointId),
                                'email'          :  this.email,
                                'journeyDate'    :  resultDate,
                                'journeyId'      :  resultJouId,
                                'journeyType'    :  parseInt(journeytype),
                                'nationally'     :  nationally,
                                'seatGender'     :  seaGen,
                                'seatJourney'    :  seatJou,
                                'seatName'       :  seatN,
                                'seatNum'        :  seatNu,
                                'seatPrice'      :  resultPrice,
                                'telephone'      :  this.phone,
                                'isUseLuckyDraw' : parseInt(this.isUseLuckyDraw),
                                'totalAmount'    :  totalAmount,
                                'totalDiscount'  :  totalDiscount.toFixed(2),
                                'totalSeat'      :  String(totalSeat),
                            }
                            const url = this.$url + `booking/confirm`;
                            let config = {
                                method: "POST",
                                headers: this.$header,
                                data: qs.stringify(data),
                                url
                            };
                            this.loading = true;
                            axios(config).then((response) => {
                                if (response.status == 200) {
                                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                                        if(response.data.body.status == 1) {
                                            setTimeout(() => {
                                                this.loading = false;
                                                this.info = response.data.body;
                                                this.transactionId = this.info.transactionId;
                                                this.$router.push({
                                                    path: "/payment-cart",
                                                    query: {
                                                        transactionId: this.info.transactionId
                                                    }
                                                });
                                            }, 1000);
                                        }else{
                                            if(response.data.body.msg == "Sorry, some seats you selected are not available."){
                                                alert("Seat is not available, please chose your trip again.");
                                                this.$router.push("/");
                                            }else{
                                                alert("Something went wrong, Please try it again.");
                                                this.$router.push("/");
                                            }
                                        }
                                    }else{
                                        this.$router.go();
                                    }
                                }
                            }).catch((error) => {
                                console.log(error);
                            }); 
                        }else{
                            this.phoneNumberError['phone'] = 'Invalid phone number';
                            alert("Invalid phone number");
                        }
                    
                    }else{
                        alert("Email is invalid");
                    }
                }
                else if(!this.getGender() && this.email != "" && this.phone != "") {
                    alert("Please select Gender");
                }else if(!this.getGender() && this.email == "" && this.phone != "") {
                    alert("Please select Gender");
                }else if(!this.getGender() && this.email != "" && this.phone == "") {
                    alert("Please select Gender");
                }else{
                    if(this.email != "") {
                        // email
                        let emails = "";
                        const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/;
                        if(regex.test(this.phone)) {
                            if(this.phone.length == this.maxLength || this.phone.length == this.maxLengths && /^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)) {
                                emails = this.email;
                                localStorage.setItem("email", emails);
                                this.msg['email'] = '';

                                // boarding point
                                let boardingPointId = localStorage.getItem('boardingPointId');
                                let dropOffPointId = localStorage.getItem('dropOffPointId');

                                // Jouney date
                                let resultDate = [];
                                let jouDate = localStorage.getItem('dateFrom');
                                resultDate.push(jouDate);

                                // journeyId 
                                let resultJouId = [];
                                let journeyId = localStorage.getItem('journeyid');
                                resultJouId.push(journeyId);

                                // journeyType
                                let journeytype = localStorage.getItem('types');

                                // nationally
                                let nationally = localStorage.getItem("selectNational");

                                // seatGender 
                                let seaGen = [];
                                let seatGender = localStorage.getItem('gender');
                                let seatGenderArray = seatGender.split(",");
                                for(let i = 0; i<seatGenderArray.length; i++) {
                                    if(seatGenderArray[i] !=",") {
                                        seaGen.push(seatGenderArray[i]);
                                    }
                                }
                        
                                // seatJourney
                                let seatJou = [];
                                let seatJourney = localStorage.getItem('seat')
                                let seatJourneyArray = seatJourney.split(",");
                                for(let i = 0; i<seatJourneyArray.length; i++) {
                                    if(seatJourneyArray[i] !=",") {
                                        seatJou.push(journeyId);
                                    }
                                }

                                // seatName
                                let seatN = [];
                                let seatName = this.fullname;
                                for (let i = 0; i<seatName.length; i++){
                                    seatN.push(seatName[i])
                                }

                                // seatNum
                                let seatNu = [];
                                let seatNum = localStorage.getItem('seat');
                                let seatNumArray = seatNum.split(",");
                                for(let i = 0; i<seatNumArray.length; i++) {
                                    if(seatNumArray[i] !=",") {
                                        seatNu.push(seatNumArray[i]);
                                    }
                                }
                        
                                // seatPrice 
                                let resultPrice = [];
                                let SPrice = localStorage.getItem('seat');
                                let SpriceArray = SPrice.split(",");
                                for (let i = 0; i < SpriceArray.length; i++) {
                                    if (SpriceArray[i] != ",") {
                                        resultPrice.push(localStorage.getItem('price'));
                                    }
                                }

                                let seatNumTotalSeat = localStorage.getItem('seat');
                                let seatNumArrayTotalSeat = seatNumTotalSeat.split(",");
                                let totalSeat = seatNumArrayTotalSeat.length;

                                let seatP = localStorage.getItem('price');
                                let totalAmount = totalSeat * seatP;
                                parseFloat(totalAmount);
                                
                                // totalDiscount
                                let totalDiscount = parseFloat(localStorage.getItem('discount')) * parseInt(totalSeat);
                                parseFloat(totalDiscount);
                                // let totalDiscount = 0;
                                // if(localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) {
                                //     totalDiscount = localStorage.getItem('resultTotal') * this.discount / 100;
                                //     parseFloat(totalDiscount);
                                // }else{
                                //     totalDiscount = 0;
                                //     parseFloat(totalDiscount);
                                // }
                                
                               
                                // Phone
                                localStorage.setItem("phone", this.phone);

                                const data = {
                                    'boardingPointId':  parseInt(boardingPointId),
                                    'dropOffId'      :  parseInt(dropOffPointId),
                                    'email'          :  emails,
                                    'journeyDate'    :  resultDate,
                                    'journeyId'      :  resultJouId,
                                    'journeyType'    :  parseInt(journeytype),
                                    'nationally'     :  nationally,
                                    'seatGender'     :  seaGen,
                                    'seatJourney'    :  seatJou,
                                    'seatName'       :  seatN,
                                    'seatNum'        :  seatNu,
                                    'seatPrice'      :  resultPrice,
                                    'telephone'      :  this.phone,
                                    'totalAmount'    :  totalAmount,
                                    'isUseLuckyDraw' :  parseInt(this.isUseLuckyDraw),
                                    'totalDiscount'  :  totalDiscount.toFixed(2),
                                    'totalSeat'      :  String(totalSeat),
                                }
                                const url = this.$url + `booking/confirm`;
                                let config = {
                                    method: "POST",
                                    headers: this.$header,
                                    data: qs.stringify(data),
                                    url
                                };
                                this.loading = true;
                                axios(config).then((response) => {
                                    if (response.status == 200) {
                                        if(response.data.header.result == true && response.data.header.statusCode == 200) {
                                            if(response.data.body.status == 1) {
                                                setTimeout(() => {
                                                    this.loading = false;
                                                    this.info = response.data.body;
                                                    this.transactionId = this.info.transactionId;
                                                    this.$router.push({
                                                        path: "/payment-cart",
                                                        query: {
                                                            transactionId: this.info.transactionId
                                                        }
                                                    });
                                                }, 1000);
                                            }else{
                                                if(response.data.body.msg == "Sorry, some seats you selected are not available."){
                                                    alert("Seat is not available, please chose your trip again.");
                                                    this.$router.push("/");
                                                }else{
                                                    alert("Something went wrong, Please try it again.");
                                                    this.$router.push("/");
                                                }
                                            }
                                        }else{
                                            this.$router.go();
                                        }
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                }); 
                            }else{
                                alert("Please enter a valid email & Telephone");
                            }
                        }else{
                            this.phoneNumberError['phone'] = 'Invalid phone number';
                            alert("Invalid phone number");
                        }
                    }else{
                       alert("Email is required");
                    }
                   
                }
            }else{
                // this.validateField = true;
                alert("Please input your name");
            }
        },
        /*--------- End One Way Boarding Point -------*/

        /*--------- Start Two Way Boarding Point -------*/
        async getBoardingPointBack(bIdBack) {
            const url = this.$url + `boarding-point/findBySchedule/${bIdBack}`;
            let config = {
                method: "POST",
                headers: this.$header,
                url
            };
            axios(config).then(response => {
                if(response.status == 200){
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.boardingPointBack = response.data.body;
                        // let boardingPointIdBack = [];
                        if(response.data.body.length == 1){
                            for(let i = 0; i < this.boardingPointBack.length; i++){
                                this.selectedBoxIdBack = this.boardingPointBack[0].id;
                                this.selectedBoxBack = this.boardingPointBack[0].address + "  " + this.convertTimes(this.boardingPointBack[0].time);
                            }
                            localStorage.setItem('boardingPointIdBack', this.selectedBoxIdBack);
                            localStorage.setItem('boardingPointNameBack', this.selectedBoxBack);
                        }else{
                            this.selectedBoxIdBack  = '';
                            this.selectedBoxBack = '';
                            localStorage.setItem('boardingPointIdBack', this.selectedBoxIdBack);
                            localStorage.setItem('boardingPointNameBack', this.selectedBoxBack);
                        }
                        
                    }else{
                        this.errored = true;
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },
        async getDropOffPointBack(dIdBack) {
            const url = this.$url +`drop-off-point/findBySchedule/${dIdBack}`;
            let config = {
                method: "POST",
                headers: this.$header,
                url
            };
            axios(config).then(response => {
                if(response.status == 200){
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.dropOffPointBack = response.data.body;
                        // let dropOffPointIdBack = response.data.body;
                        if(response.data.body.length == 1){
                            for(let i = 0; i <this.dropOffPointBack.length; i++) {
                                this.selectedBoxIdBackDown = this.dropOffPointBack[0].id;
                                this.selectedBoxBackDown = this.dropOffPointBack[0].address + "  " + this.convertTimes(this.dropOffPointBack[0].time);
                            }
                            localStorage.setItem('dropOffPointIdBack', this.selectedBoxIdBackDown);
                            localStorage.setItem('dropOffPointNameBack', this.selectedBoxBackDown);
                        }else{
                            this.selectedBoxIdBackDown = '';
                            this.selectedBoxBackDown = '';
                            localStorage.setItem('dropOffPointIdBack', this.selectedBoxIdBackDown);
                            localStorage.setItem('dropOffPointNameBack', this.selectedBoxBackDown);
                        }
                       
                    }else{
                        this.errored = true
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        },
        processPaymentTwo(){
            if(this.seatLabels.length == this.fullname.length && this.checkName() && this.seatLabelbacks.length == this.fullnameback.length && this.checkNameBack()){
                if(!this.getGender() && !this.getGenderBack() && this.email == "" && this.phone == "") {
                    alert("Please input Email or Select Gender");
                }else if(this.email == "" && this.phone == "" && this.getGender() && this.getGenderBack()){
                    alert("Please input Email.")
                }else if(this.isUseLuckyDraw == 1 && this.phone == ""){
                    alert("Please input telephone number");
                }else if(localStorage.getItem('boardingPointId') == '' || localStorage.getItem('boardingPointIdBack') == ''){
                    alert("Please Select boarding point");
                }else if(localStorage.getItem('dropOffPointId') == '' || localStorage.getItem('dropOffPointIdBack') == ''){
                    alert("Please Select Drop Off point");
                }else if(this.phone == "" && this.email != "" && this.getGender() && this.getGenderBack()){
                    // email
                    let emails = "";
                    if (/^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)) {
                        emails = this.email;
                        localStorage.setItem("email", emails);
                        this.msg['email'] = '';

                        // boarding point & back
                        let boardingPointId = localStorage.getItem('boardingPointId');
                        let dropOffPointId = localStorage.getItem('dropOffPointId');
                        let boardingPointIdBack = localStorage.getItem('boardingPointIdBack');
                        let dropOffPointIdBack = localStorage.getItem('dropOffPointIdBack');

                        // Jouney date & back
                        let resultDate = [];
                        let jouDate = localStorage.getItem('dateFrom');
                        let jouDateBack = localStorage.getItem('dateTo');
                        resultDate.push(jouDate);
                        resultDate.push(jouDateBack);

                        // journeyId & back
                        let resultJouId = [];
                        let journeyId = localStorage.getItem('journeyid');
                        let journeyIdBack = localStorage.getItem('journeyidback');
                        resultJouId.push(journeyId);
                        resultJouId.push(journeyIdBack);

                        // journeyType
                        // let journeytype = localStorage.getItem('types');

                        // nationally
                        let nationally = localStorage.getItem("selectNational");

                        // seatGender & back
                        let seaGen = [];
                        let seatGender = localStorage.getItem('gender');
                        let seatGenderArray = seatGender.split(",");
                        for(let i = 0; i<seatGenderArray.length; i++) {
                            if(seatGenderArray[i] !=",") {
                                seaGen.push(seatGenderArray[i]);
                            }
                        }

                        let seatGenderBack = localStorage.getItem('genderback')
                        let seatGenderArrayBack = seatGenderBack.split(",");
                        for(let i = 0; i< seatGenderArrayBack.length; i++){
                            if(seatGenderArrayBack[i] !=",") {
                                seaGen.push(seatGenderArrayBack[i]);
                            }
                        }

                        // seatJourney & back
                        let seatJou = [];
                        let seatJourney = localStorage.getItem('seat');
                        let seatJourneyArray = seatJourney.split(",");
                        for(let i = 0; i<seatJourneyArray.length; i++) {
                            if(seatJourneyArray[i] !=",") {
                                seatJou.push(journeyId);
                            }
                        }

                        let seatJourneyBacks = localStorage.getItem('seatback');
                        let seatJourneyBackArray = seatJourneyBacks.split(",");
                        for(let i = 0; i<seatJourneyBackArray.length; i++) {
                            if(seatJourneyBackArray[i] !=",") {
                                seatJou.push(journeyIdBack);
                            }
                        }

                        // seatName & back
                        let seatN = [];
                        let seatName = this.fullname;
                        for (let i = 0; i<seatName.length; i++){
                            seatN.push(seatName[i])
                        }

                            
                        if(this.fullnameback != "") {
                            let seatNameBack = this.fullnameback;
                            for (let i = 0; i<seatNameBack.length; i++){
                                seatN.push(seatNameBack[i])
                            }
                        }

                        
                        // seatNum & back
                        let seatNu = [];
                        let seatNum = localStorage.getItem('seat');
                        let seatNumArray = seatNum.split(",");
                        for(let i = 0; i<seatNumArray.length; i++) {
                            if(seatNumArray[i] !=",") {
                                seatNu.push(seatNumArray[i]);
                            }
                        }

                        let seatNumBack = localStorage.getItem('seatback');
                        let seatNumArrayBack = seatNumBack.split(",");
                        for(let i = 0; i<seatNumArrayBack.length; i++) {
                            if(seatNumArrayBack[i] !=",") {
                                seatNu.push(seatNumArrayBack[i]);
                            }
                        }

                        // seatPrice & back
                        this.totalAmount = 0;
                        let resultPrice = [];
                        let SPrice = localStorage.getItem('seat');
                        let SpriceArray = SPrice.split(",");
                        for (let i = 0; i < SpriceArray.length; i++) {
                            if (SpriceArray[i] != ",") {
                                resultPrice.push(localStorage.getItem('price'));
                                this.totalAmount += parseFloat(localStorage.getItem('price'));
                            }
                        }
                        let priceBa = localStorage.getItem('seatback');
                        let seatPriceBacks = priceBa.split(",");
                        for (let i = 0; i < seatPriceBacks.length; i++) {
                            if(seatPriceBacks[i] != ',') {
                                resultPrice.push(localStorage.getItem('priceback'));
                                this.totalAmount += parseFloat(localStorage.getItem('priceback'));
                            }
                        }

                        let seatNumTotalSeat = localStorage.getItem('seat');
                        let seatNumArrayTotalSeat = seatNumTotalSeat.split(",");
                        let seatNumTotalSeatBack = localStorage.getItem('seatback');
                        let seatNumArrayTotalSeatBack = seatNumTotalSeatBack.split(",");

                        let totalSeat = seatNumArrayTotalSeat.length;
                        let totalSeatBack = seatNumArrayTotalSeatBack.length;
                        let plusPrice = totalSeat + totalSeatBack

                        // totalDiscount
                        let totalDiscount = 0;
                        let totalDiscosuntnoreturn =  parseFloat(localStorage.getItem('discount')) * parseInt(totalSeat);
                        let totalDiscosuntReturn  = parseFloat(localStorage.getItem('discountBack')) * parseInt(totalSeatBack);
                        totalDiscount = totalDiscosuntnoreturn + totalDiscosuntReturn;
                        parseFloat(totalDiscount);
                        // if((localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) && (localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null)) {
                        //     let totalDis = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                        //     totalDiscount = totalDis * this.discount/100;
                        //     parseFloat(totalDiscount);
                        // }else if((localStorage.getItem("priceOriginal") != "" || localStorage.getItem("priceOriginal") != null) && (localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null)){
                        //     let totalDis = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                        //     totalDiscount = totalDis * this.discount/100;
                        //     console.log(totalDiscount);
                        //     parseFloat(totalDiscount);
                        // }else if((localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) && (localStorage.getItem("priceOriginalback") != "" || localStorage.getItem("priceOriginalback") != null)){
                        //     let totalDis = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                        //     totalDiscount = totalDis * this.discount/100;
                        //     parseFloat(totalDiscount);
                        // }else{
                        //     totalDiscount = 0;
                        //     parseFloat(totalDiscount);
                        // }

                        
                     
                        // Phone
                        localStorage.setItem("phone", this.phone);

                        const data = {
                            'boardingPointId': Array(boardingPointId,boardingPointIdBack),
                            'dropOffId'      : Array(dropOffPointId, dropOffPointIdBack),
                            'email'          : emails,
                            'journeyDate'    : resultDate,
                            'journeyId'      : resultJouId,
                            'journeyType'    : 2,
                            'nationally'     : nationally,
                            'seatGender'     : seaGen,
                            'seatJourney'    : seatJou,
                            'seatName'       : seatN,
                            'seatNum'        : seatNu,
                            'seatPrice'      : resultPrice,
                            'telephone'      : this.phone,
                            'totalAmount'    : parseFloat(this.totalAmount),
                            'totalDiscount'  : totalDiscount.toFixed(2),
                            'isUseLuckyDraw' :  parseInt(this.isUseLuckyDraw),
                            'totalSeat'      : String(plusPrice),
                        };
                        // console.log(data);
                        const url = this.$url + `booking/confirm`;
                        let config = {
                            method: "POST",
                            headers: this.$header,
                            data: qs.stringify(data),
                            url
                        };
                        this.loading = true;
                        axios(config).then((response) => {
                            // console.log(response);
                            if (response.status == 200) {
                                if(response.data.header.result == true && response.data.header.statusCode == 200) {
                                    if(response.data.body.status == 1) {
                                        setTimeout(() => {
                                            this.loading = false;
                                            this.info = response.data.body;
                                            this.transactionId = this.info.transactionId;
                                            this.$router.push({
                                                path: "/payment-cart",
                                                query: {
                                                    transactionId: this.info.transactionId
                                                }
                                            });
                                        }, 1000);
                                    }else{
                                        if(response.data.body.msg == "Sorry, some seats you selected are not available."){
                                            alert("Seat is not available, please chose your trip again.");
                                            this.$router.push("/");
                                        }else{
                                            alert("Something went wrong, Please try it again.");
                                            this.$router.push("/");
                                        }
                                    }
                                }else{
                                    this.$router.go();
                                }
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    }else{
                        this.msg['email'] = 'Please enter a valid email address';
                        alert("Please enter a valid email address");
                    }
                }else if(this.email !== "" && this.phone != "" && this.getGender() && this.getGenderBack()){
                    const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/;
                    if(regex.test(this.phone) && /^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)) {
                        if(this.phone.length == this.maxLength || (this.phone.length == this.maxLengths)) {
                            // boarding point & back
                            let boardingPointId = localStorage.getItem('boardingPointId');
                            let dropOffPointId = localStorage.getItem('dropOffPointId');
                            let boardingPointIdBack = localStorage.getItem('boardingPointIdBack');
                            let dropOffPointIdBack = localStorage.getItem('dropOffPointIdBack');

                            // Jouney date & back
                            let resultDate = [];
                            let jouDate = localStorage.getItem('dateFrom');
                            let jouDateBack = localStorage.getItem('dateTo');
                            resultDate.push(jouDate);
                            resultDate.push(jouDateBack);

                            // journeyId & back
                            let resultJouId = [];
                            let journeyId = localStorage.getItem('journeyid');
                            let journeyIdBack = localStorage.getItem('journeyidback');
                            resultJouId.push(journeyId);
                            resultJouId.push(journeyIdBack);

                            // journeyType
                            // let journeytype = localStorage.getItem('types');

                            // nationally
                            let nationally = localStorage.getItem("selectNational");

                            // seatGender & back
                            let seaGen = [];
                            let seatGender = localStorage.getItem('gender');
                            let seatGenderArray = seatGender.split(",");
                            for(let i = 0; i<seatGenderArray.length; i++) {
                                if(seatGenderArray[i] !=",") {
                                    seaGen.push(seatGenderArray[i]);
                                }
                            }

                            let seatGenderBack = localStorage.getItem('genderback')
                            let seatGenderArrayBack = seatGenderBack.split(",");
                            for(let i = 0; i< seatGenderArrayBack.length; i++){
                                if(seatGenderArrayBack[i] !=",") {
                                    seaGen.push(seatGenderArrayBack[i]);
                                }
                            }

                            // seatJourney & back
                            let seatJou = [];
                            let seatJourney = localStorage.getItem('seat');
                            let seatJourneyArray = seatJourney.split(",");
                            for(let i = 0; i<seatJourneyArray.length; i++) {
                                if(seatJourneyArray[i] !=",") {
                                    seatJou.push(journeyId);
                                }
                            }

                            let seatJourneyBacks = localStorage.getItem('seatback');
                            let seatJourneyBackArray = seatJourneyBacks.split(",");
                            for(let i = 0; i<seatJourneyBackArray.length; i++) {
                                if(seatJourneyBackArray[i] !=",") {
                                    seatJou.push(journeyIdBack);
                                }
                            }

                            // seatName & back
                            let seatN = [];
                            let seatName = this.fullname;
                            for (let i = 0; i<seatName.length; i++){
                                seatN.push(seatName[i])
                            }

                            
                            if(this.fullnameback != "") {
                                let seatNameBack = this.fullnameback;
                                for (let i = 0; i<seatNameBack.length; i++){
                                    seatN.push(seatNameBack[i])
                                }
                            }

                        
                            // seatNum & back
                            let seatNu = [];
                            let seatNum = localStorage.getItem('seat');
                            let seatNumArray = seatNum.split(",");
                            for(let i = 0; i<seatNumArray.length; i++) {
                                if(seatNumArray[i] !=",") {
                                    seatNu.push(seatNumArray[i]);
                                }
                            }

                            let seatNumBack = localStorage.getItem('seatback');
                            let seatNumArrayBack = seatNumBack.split(",");
                            for(let i = 0; i<seatNumArrayBack.length; i++) {
                                if(seatNumArrayBack[i] !=",") {
                                    seatNu.push(seatNumArrayBack[i]);
                                }
                            }

                            // seatPrice & back
                            this.totalAmount = 0;
                            let resultPrice = [];
                            let SPrice = localStorage.getItem('seat');
                            let SpriceArray = SPrice.split(",");
                            for (let i = 0; i < SpriceArray.length; i++) {
                                if (SpriceArray[i] != ",") {
                                    resultPrice.push(localStorage.getItem('price'));
                                    this.totalAmount += parseFloat(localStorage.getItem('price'));
                                }
                            }
                            let priceBa = localStorage.getItem('seatback');
                            let seatPriceBacks = priceBa.split(",");
                            for (let i = 0; i < seatPriceBacks.length; i++) {
                                if(seatPriceBacks[i] != ',') {
                                    resultPrice.push(localStorage.getItem('priceback'));
                                    this.totalAmount += parseFloat(localStorage.getItem('priceback'));
                                }
                            }

                            let seatNumTotalSeat = localStorage.getItem('seat');
                            let seatNumArrayTotalSeat = seatNumTotalSeat.split(",");
                            let seatNumTotalSeatBack = localStorage.getItem('seatback');
                            let seatNumArrayTotalSeatBack = seatNumTotalSeatBack.split(",");

                            let totalSeat = seatNumArrayTotalSeat.length;
                            let totalSeatBack = seatNumArrayTotalSeatBack.length;
                            let plusPrice = totalSeat + totalSeatBack

                            // totalDiscount
                            let totalDiscount = 0;
                            let totalDiscosuntnoreturn =  parseFloat(localStorage.getItem('discount')) * parseInt(totalSeat);
                            let totalDiscosuntReturn  = parseFloat(localStorage.getItem('discountBack')) * parseInt(totalSeatBack);
                            totalDiscount = totalDiscosuntnoreturn + totalDiscosuntReturn;
                            parseFloat(totalDiscount);
                            // let totalDiscount = 0;
                            // if((localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) && (localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null)) {
                            //     let totalDis = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                            //     totalDiscount = totalDis * this.discount/100;
                            //     parseFloat(totalDiscount);
                            // }else if((localStorage.getItem("priceOriginal") != "" || localStorage.getItem("priceOriginal") != null) && (localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null)){
                            //     let totalDis = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                            //     totalDiscount = totalDis * this.discount/100;
                            //     console.log(totalDiscount);
                            //     parseFloat(totalDiscount);
                            // }else if((localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) && (localStorage.getItem("priceOriginalback") != "" || localStorage.getItem("priceOriginalback") != null)){
                            //     let totalDis = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                            //     totalDiscount = totalDis * this.discount/100;
                            //     parseFloat(totalDiscount);
                            // }else{
                            //     totalDiscount = 0;
                            //     parseFloat(totalDiscount);
                            // }

                            // Phone
                            localStorage.setItem("phone", this.phone);
                            localStorage.setItem("email", this.email);

                            const data = {
                                'boardingPointId': Array(boardingPointId,boardingPointIdBack),
                                'dropOffId'      : Array(dropOffPointId, dropOffPointIdBack),
                                'email'          : this.email,
                                'journeyDate'    : resultDate,
                                'journeyId'      : resultJouId,
                                'journeyType'    : 2,
                                'nationally'     : nationally,
                                'seatGender'     : seaGen,
                                'seatJourney'    : seatJou,
                                'seatName'       : seatN,
                                'seatNum'        : seatNu,
                                'seatPrice'      : resultPrice,
                                'telephone'      : this.phone,
                                'totalAmount'    : parseFloat(this.totalAmount),
                                'isUseLuckyDraw' : parseInt(this.isUseLuckyDraw),
                                'totalDiscount'  : totalDiscount.toFixed(2),
                                'totalSeat'      : String(plusPrice),
                            };
                            const url = this.$url + `booking/confirm`;
                            let config = {
                                method: "POST",
                                headers: this.$header,
                                data: qs.stringify(data),
                                url
                            };
                            this.loading = true;
                            axios(config).then((response) => {
                                if (response.status == 200) {
                                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                                        if(response.data.body.status == 1) {
                                            setTimeout(() => {
                                                this.loading = false;
                                                this.info = response.data.body;
                                                this.transactionId = this.info.transactionId;
                                                this.$router.push({
                                                    path: "/payment-cart",
                                                    query: {
                                                        transactionId: this.info.transactionId
                                                    }
                                                });
                                            }, 1000);
                                        }else{
                                            if(response.data.body.msg == "Sorry, some seats you selected are not available."){
                                                alert("Seat is not available, please chose your trip again.");
                                                this.$router.push("/");
                                            }else{
                                                alert("Something went wrong, Please try it again.");
                                                this.$router.push("/");
                                            }
                                        }
                                    }else{
                                        this.$router.go();
                                    }
                                }
                            }).catch((error) => {
                                console.log(error);
                            });
                        }else{
                            this.phoneNumberError['phone'] = 'Invalid phone number';
                            alert("Invalid phone number");
                        }
                    }else{
                        alert("Email is invalid");
                    }
                }else if(!this.getGender() && !this.getGenderBack() && this.email == "" && this.phone != "") {
                    alert("Please select Gender");
                }else if(!this.getGender() && this.getGenderBack() && this.email == "" && this.phone != "") {
                    alert("Please select Gender");
                }else if(this.getGender() && !this.getGenderBack() && this.email == "" && this.phone != "") {
                    alert("Please select Gender");
                }else if(!this.getGender() && !this.getGenderBack() && this.email != "" && this.phone == "") {
                    alert("Please select Gender");
                }else if(this.getGender() && !this.getGenderBack() && this.email != "" && this.phone == "") {
                    alert("Please select Gender");
                }else if(!this.getGender() && this.getGenderBack() && this.email != "" && this.phone == "") { 
                    alert("Please select Gender");
                }else if(!this.getGender() && !this.getGenderBack() && this.email != "" && this.phone != "") { 
                    alert("Please select Gender");
                }else if(this.getGender() && !this.getGenderBack() && this.email != "" && this.phone != "") { 
                    alert("Please select Gender");
                }else if(!this.getGender() && this.getGenderBack() && this.email != "" && this.phone != "") { 
                    alert("Please select Gender");
                }else{
                    if(this.email != "") {
                        // email
                        let emails = "";
                        const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/;
                        if(regex.test(this.phone)) {
                            if (this.phone.length == this.maxLength && /^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)) {
                                emails = this.email;
                                localStorage.setItem("email", emails);
                                this.msg['email'] = '';

                                // boarding point & back
                                let boardingPointId = localStorage.getItem('boardingPointId');
                                let dropOffPointId = localStorage.getItem('dropOffPointId');
                                let boardingPointIdBack = localStorage.getItem('boardingPointIdBack');
                                let dropOffPointIdBack = localStorage.getItem('dropOffPointIdBack');

                                // Jouney date & back
                                let resultDate = [];
                                let jouDate = localStorage.getItem('dateFrom');
                                let jouDateBack = localStorage.getItem('dateTo');
                                resultDate.push(jouDate);
                                resultDate.push(jouDateBack);

                                // journeyId & back
                                let resultJouId = [];
                                let journeyId = localStorage.getItem('journeyid');
                                let journeyIdBack = localStorage.getItem('journeyidback');
                                resultJouId.push(journeyId);
                                resultJouId.push(journeyIdBack);

                                // journeyType
                                // let journeytype = localStorage.getItem('types');

                                // nationally
                                let nationally = localStorage.getItem("selectNational");

                                // seatGender & back
                                let seaGen = [];
                                let seatGender = localStorage.getItem('gender');
                                let seatGenderArray = seatGender.split(",");
                                for(let i = 0; i<seatGenderArray.length; i++) {
                                    if(seatGenderArray[i] !=",") {
                                        seaGen.push(seatGenderArray[i]);
                                    }
                                }

                                let seatGenderBack = localStorage.getItem('genderback')
                                let seatGenderArrayBack = seatGenderBack.split(",");
                                for(let i = 0; i< seatGenderArrayBack.length; i++){
                                    if(seatGenderArrayBack[i] !=",") {
                                        seaGen.push(seatGenderArrayBack[i]);
                                    }
                                }

                                // seatJourney & back
                                let seatJou = [];
                                let seatJourney = localStorage.getItem('seat');
                                let seatJourneyArray = seatJourney.split(",");
                                for(let i = 0; i<seatJourneyArray.length; i++) {
                                    if(seatJourneyArray[i] !=",") {
                                        seatJou.push(journeyId);
                                    }
                                }

                                let seatJourneyBacks = localStorage.getItem('seatback');
                                let seatJourneyBackArray = seatJourneyBacks.split(",");
                                for(let i = 0; i<seatJourneyBackArray.length; i++) {
                                    if(seatJourneyBackArray[i] !=",") {
                                        seatJou.push(journeyIdBack);
                                    }
                                }

                                // seatName & back
                                let seatN = [];
                                let seatName = this.fullname;
                                for (let i = 0; i<seatName.length; i++){
                                    seatN.push(seatName[i])
                                }

                                
                                if(this.fullnameback != "") {
                                    let seatNameBack = this.fullnameback;
                                    for (let i = 0; i<seatNameBack.length; i++){
                                        seatN.push(seatNameBack[i])
                                    }
                                }

                                
                                // seatNum & back
                                let seatNu = [];
                                let seatNum = localStorage.getItem('seat');
                                let seatNumArray = seatNum.split(",");
                                for(let i = 0; i<seatNumArray.length; i++) {
                                    if(seatNumArray[i] !=",") {
                                        seatNu.push(seatNumArray[i]);
                                    }
                                }

                                let seatNumBack = localStorage.getItem('seatback');
                                let seatNumArrayBack = seatNumBack.split(",");
                                for(let i = 0; i<seatNumArrayBack.length; i++) {
                                    if(seatNumArrayBack[i] !=",") {
                                        seatNu.push(seatNumArrayBack[i]);
                                    }
                                }

                                // seatPrice & back
                                this.totalAmount = 0;
                                let resultPrice = [];
                                let SPrice = localStorage.getItem('seat');
                                let SpriceArray = SPrice.split(",");
                                for (let i = 0; i < SpriceArray.length; i++) {
                                    if (SpriceArray[i] != ",") {
                                        resultPrice.push(localStorage.getItem('price'));
                                        this.totalAmount += parseFloat(localStorage.getItem('price'));
                                    }
                                }
                                let priceBa = localStorage.getItem('seatback');
                                let seatPriceBacks = priceBa.split(",");
                                for (let i = 0; i < seatPriceBacks.length; i++) {
                                    if(seatPriceBacks[i] != ',') {
                                        resultPrice.push(localStorage.getItem('priceback'));
                                        this.totalAmount += parseFloat(localStorage.getItem('priceback'));
                                    }
                                }

                                let seatNumTotalSeat = localStorage.getItem('seat');
                                let seatNumArrayTotalSeat = seatNumTotalSeat.split(",");
                                let seatNumTotalSeatBack = localStorage.getItem('seatback');
                                let seatNumArrayTotalSeatBack = seatNumTotalSeatBack.split(",");

                                let totalSeat = seatNumArrayTotalSeat.length;
                                let totalSeatBack = seatNumArrayTotalSeatBack.length;
                                let plusPrice = totalSeat + totalSeatBack

                                // totalDiscount
                                let totalDiscount = 0;
                                let totalDiscosuntnoreturn =  parseFloat(localStorage.getItem('discount')) * parseInt(totalSeat);
                                let totalDiscosuntReturn  = parseFloat(localStorage.getItem('discountBack')) * parseInt(totalSeatBack);
                                totalDiscount = totalDiscosuntnoreturn + totalDiscosuntReturn;
                                parseFloat(totalDiscount);
                                // let totalDiscount = 0;
                                // if((localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) && (localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null)) {
                                //     let totalDis = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                                //     totalDiscount = totalDis * this.discount/100;
                                //     parseFloat(totalDiscount);
                                // }else if((localStorage.getItem("priceOriginal") != "" || localStorage.getItem("priceOriginal") != null) && (localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null)){
                                //     let totalDis = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                                //     totalDiscount = totalDis * this.discount/100;
                                //     console.log(totalDiscount);
                                //     parseFloat(totalDiscount);
                                // }else if((localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null) && (localStorage.getItem("priceOriginalback") != "" || localStorage.getItem("priceOriginalback") != null)){
                                //     let totalDis = parseFloat(localStorage.getItem('resultTotal')) + parseFloat(localStorage.getItem('resultTotalBack'));
                                //     totalDiscount = totalDis * this.discount/100;
                                //     parseFloat(totalDiscount);
                                // }else{
                                //     totalDiscount = 0;
                                //     parseFloat(totalDiscount);
                                // }

                                // Phone
                                localStorage.setItem("phone", this.phone);

                                const data = {
                                    'boardingPointId': Array(boardingPointId,boardingPointIdBack),
                                    'dropOffId'      : Array(dropOffPointId, dropOffPointIdBack),
                                    'email'          : emails,
                                    'journeyDate'    : resultDate,
                                    'journeyId'      : resultJouId,
                                    'journeyType'    : 2,
                                    'nationally'     : nationally,
                                    'seatGender'     : seaGen,
                                    'seatJourney'    : seatJou,
                                    'seatName'       : seatN,
                                    'seatNum'        : seatNu,
                                    'seatPrice'      : resultPrice,
                                    'telephone'      : this.phone,
                                    'totalAmount'    : parseFloat(this.totalAmount),
                                    'isUseLuckyDraw' : parseInt(this.isUseLuckyDraw),
                                    'totalDiscount'  : totalDiscount.toFixed(2),
                                    'totalSeat'      : String(plusPrice),
                                };
                                // console.log(data);
                                const url = this.$url + `booking/confirm`;
                                let config = {
                                    method: "POST",
                                    headers: this.$header,
                                    data: qs.stringify(data),
                                    url
                                };
                                this.loading = true;
                                axios(config).then((response) => {
                                    // console.log(response);
                                    if (response.status == 200) {
                                        if(response.data.header.result == true && response.data.header.statusCode == 200) {
                                            if(response.data.body.status == 1) {
                                                setTimeout(() => {
                                                    this.loading = false;
                                                    this.info = response.data.body;
                                                    this.transactionId = this.info.transactionId;
                                                    this.$router.push({
                                                        path: "/payment-cart",
                                                        query: {
                                                            transactionId: this.info.transactionId
                                                        }
                                                    });
                                                }, 1000);
                                            }else{
                                                if(response.data.body.msg == "Sorry, some seats you selected are not available."){
                                                    alert("Seat is not available, please chose your trip again.");
                                                    this.$router.push("/");
                                                }else{
                                                    alert("Something went wrong, Please try it again.");
                                                    this.$router.push("/");
                                                }
                                            }
                                        }else{
                                            this.$router.go();
                                        }
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                });
                            }else{
                                alert("Please enter a valid email & Telephone");
                            }
                        }else{
                            this.phoneNumberError['phone'] = 'Invalid phone number';
                            alert("Invalid phone number");
                        }
                    }else{
                        alert("Email is required");
                    }
                }
            }else{
                alert("Please input your name");
            }
        },
        /*--------- End Two Way Boarding Point -------*/
        validatePhoneNumber(value) {
            const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/; // regular expression to validate phone number
            if (regex.test(value)) {
                if (value.length < this.maxLength) {
                    this.phoneNumberError['phone'] = 'Telephone number is invalid';
                }else if(value.length <= this.maxLength || this.phone.length == this.maxLengths){ 
                    this.phoneNumberError['phone'] = ''; // valid phone number within length limit
                }else {
                    this.phoneNumberError['phone'] = 'Telephone number is invalid'; // invalid phone number exceeding length limit
                }
            } else {
                this.phoneNumberError['phone'] = 'Telephone number is invalid'; // invalid phone number
            }
        },
        removefixscroll(){
            const htmlElement = document.querySelector('html');
            htmlElement.classList.remove('fix-scroll');
        },
        validateEmail(value) {
            if (/^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                this.msg['email'] = '';
            } else{
                this.msg['email'] = 'Email is invalid';
            }
        },
        limitStr (string, limit) {
            let str = string;

            if (typeof str === 'string' && str.length > limit) {
                str = str.slice(0, limit) + '...';
            }
            
            return str;
        }
    },
    mounted() {
        this.removefixscroll();
        window.onpopstate = () => {
            if(localStorage.getItem('dateTo') != null) {
                localStorage.removeItem("seatback");
                localStorage.removeItem("seatlabelback");
            }else{
                localStorage.removeItem("seat");
                localStorage.removeItem("seatlabel");
            }
        };
        this.$emit("input", this.selectedBox);
        this.$emit("input", this.selectedBoxDown);
    },
    created() {
        if(localStorage.getItem('selectNational') == 2) {
            this.checkNational = true;
        }else {
            this.checkNational = false;
        }
        // if(localStorage.getItem("priceOriginal") == "" || localStorage.getItem("priceOriginal") == null){
        //     this.showDiscount = true;
        // }else{
        //     if(localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null){
        //         this.showDiscountBack = true;
        //     }else{
        //         this.showDiscount = false;
        //     }
        // }
        // if(localStorage.getItem("priceOriginalback") == "" || localStorage.getItem("priceOriginalback") == null){
        //     this.showDiscountBack = true;
        // }else{
        //     this.showDiscountBack = false;
        // }
        setTimeout(() => {
            if(localStorage.getItem('seatlabelback') == '' || localStorage.getItem('seatlabelback') == null) {
                this.getBoardingPoint(localStorage.getItem('journeyid'));
                this.getDropOffPoint(localStorage.getItem('journeyid'));
            }else{
                this.getBoardingPoint(localStorage.getItem('journeyid'));
                this.getDropOffPoint(localStorage.getItem('journeyid'));
                this.getBoardingPointBack(localStorage.getItem('journeyidback'));
                this.getDropOffPointBack(localStorage.getItem('journeyidback'));
            }
            this.getCheck();
            this.getSeatLabelRetureBack();
        }, 1000);
    }
}
</script>

<style scoped>
    .hideNational {
        transition: .5s;
        display: none;
    }
    .passenger-pd{
        background-color: #F2F2F2;
        padding-top: 25px;
    }
    .showNational {
        display: block;
    }
    .mtop {
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .title-booking-detail p{
        font-size: 20px;
        font-weight: 600;
        color: #1D1D1D;
    }
    .custom-checkbox {
        display: inline-block;
        position: relative;
        cursor: pointer;
        user-select: none;
    }

    .custom-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: #fff;
        border: 2px solid #ccc;
        border-radius: 4px;
    }

    /* .custom-checkbox input:checked ~ .checkmark {
        border: 2px solid #4CAF50;
    } */

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 1px;
        width: 7px;
        height: 18px;
        border: solid #4CAF50;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }

    .custom-checkbox input:checked ~ .checkmark:after {
        display: block;
    }
    .bg-lights {
        width: 35px;
        height: 35px;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #DE5D0A;
        border-radius: 50%;
    }
    .img-note {
        display: flex;
        align-items: center;
    }
    .img-note div {
        margin-right: 10px;
    }
    .bg-lights img {
        width: 85%;
        height: 85%;
        border-radius: 50%;
    }
    .noted-text {
        margin-top: 14px;
        padding-left: 45px;
    }
    .name-width{
        margin-left: -25px; 
        width:80%;
    }
    .map-point-width{
        width:20%;
    }
    /* Right */
    .passDetails {
        display: flex;
        align-items: center;
    }
    .passenger {
        padding-top: 17px;
        padding-left: 45px;
    }
    .d-none-for-mobile{
        display: none;
    }
    .padd-lefe {
        padding-left: 80px;
    }
    .passDetails .number {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 600;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid #312783;
        background: #312783;
    }
    .passDetails .title-pass {
        margin-left: 15px;
        font-weight: 600;
        font-size: 16px;
        color: #424245;
    }
    .custom-input {
        cursor: pointer;
        padding-left: 12px;
        border-radius: 4px;
        width: 100%;
        height: 40px;
        outline: none;
        border: 1px solid #6E6E73;
    }
    .select-radio {
        padding-top: 20px;
        padding-left: 44px;
    }
    .select-radio .card-select-payment {
        background: #EEEEF3;
        margin-top: 20px;
        padding: 25px;
        border-radius: 6px;
        display: flex;
        align-items: center;
    }
    .select-radio .card-select-payment .text .title {
        font-weight: 600;
        font-size: 12px !important;
        color: #424245;
       
    }
    .dotted {
        display: none;
        padding-top: 10px;
    }
    .select-radio .card-select-payment .text .txt {
        font-weight: 400;
        font-size: 12px !important;
        color: #6E6E73;
    }
    .select-radio .card-select-payment .img-card {
        width: 10%;
    }
    .select-radio .card-select-payment .text {
        width: 80%;
    }
    .select-radio .card-select-payment .radios {
        display: flex;
        justify-content: end;
        width: 10%;
    }
    
    /* left */
    .fare-detail {
        font-weight: 600;
        font-size: 16px;
        color: #424245;
    }
    .d-flexs {
        margin-top: 10px;
        display: flex;
        align-items: center;
    }
    .d-flexs .static {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        width: 35%;  
    }
    .smallLineHeight {
        line-height: 25px;
    }

    .largeLineHeight {
        line-height: 40px;
    }
    .d-flexs .dynamic {
        margin-left: 5%;
        width: 70%;
        font-size: 14px;
        font-weight: 400;
        color: #1D1D1D;
    }
    .total {
        margin-top: 25px;
        display: flex;
        align-items: center;
        padding: 15px;
        border-radius: 6px;
        background: #D9D9D9;
    }
    .total div {
        width: 50%;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
    }
    .total .price {
        text-align: end;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
    }
    .check-box {
        display: flex;
        align-items: center;
    }
    .check-box .text {
        margin-top: 10px;
    }
    .proccess {
        cursor: pointer;
        margin-top: 5px;
        text-align: center;
        padding: 15px;
        border-radius: 6px;
        font-weight: 600;
        color: #fff;
        font-size: 16px;
        background: #DE5D0A;
    }
    .apply{
        cursor: pointer;
        text-align: center;
        padding: 10px;
        border-radius: 6px;
        font-weight: 600;
        color: #fff;
        font-size: 16px;
        background: #DE5D0A;
    }
    .theme--light.v-icon{
        color:#C6C6C6 !important;
        /* border-color: transparent !important; */
    }
    .theme--light.v-input--selection-controls .v-icon {
        color: transparent !important;
    }
    .hide-label {
        display: none;
    }

    .custom-select {
        margin-top: 0px;
        position: relative;
        width: 100%;
        text-align: left;
        outline: none;
        line-height: 24px;
        padding-top: 5px;
    }
    .custom-select .selected {
        border-radius: 6px;
        border: 1px solid #6E6E73;
        color: #000;
        padding-left: 1em;
        cursor: pointer;
        user-select: none;
        padding: 11px 6px;
    }
    
    .custom-select .selected.open {
        border-radius: 6px;
    }
    .header-drop-point{
        color: #1D1D1D; 
        cursor: text !important; 
        font-size: 18px; 
        font-weight: 600;
        padding-bottom: 14px;
        padding-top: 14px;
    }
    .right-info-name-map{
        border: 1px solid #C6C6C699; 
        width: 90%; 
        border-radius: 8px; 
        /* padding: 0px 10px; */
        padding-top: 10px;
        padding-left: 10px;
        /* padding-right: 10px; */
    }
    .custom-select .selected:after {
        position: absolute;
        content: "";
        top: 27px !important;
        right: 1em;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-color: #666666 transparent transparent transparent;
    }
    .hideAfterdrop:after{
        content:none !important;
    }
    .custom-select .items {
        color: #000;
        border-radius: 0px 0px 6px 6px;
        overflow: hidden;
        position: absolute;
        background-color: #FFF;
        left: 0;
        right: 0;
        z-index: 1;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    }
    /* .custom-select .items div:hover{
        color: #312783 !important;
       
    } */
    .custom-select .items div {
        color: #000;
        padding-left: 1em;
        cursor: pointer;
        user-select: none;
    }
   
    /* .custom-select .items div:hover {
        background-color: white;
    } */
    .selectHide {
        display: none;
    }
    .details-screen{
        padding-top: 6px;
    }
    
    .proccessed {
        cursor: not-allowed;
        margin-top: 5px;
        text-align: center;
        padding: 15px;
        border-radius: 6px;
        font-weight: 600;
        color: #fff;
        font-size: 16px;
        background: #6A6969;
    }
    @media(width < 960px) {
        .select-radio {
            padding-left: 0px;
        }
        .select-radio .card-select-payment .text { 
            padding-left: 20px;
        }
        .passenger {
            padding-left: 0px;
        }
        .padd-lefe {
            padding-left: 10px;
        }
        .hide-label-phone {
            display: none;
        }
        .hide-label {
            display: block;
        }
        .details-screen , .passDetails-info{
            padding-top: 20px !important;
        }
        .passDetails-number{
            padding-bottom: 10px;
        }
    }
    
    @media(width < 600px) {
        .dotted {
            display: block;
        }
        .group-form {
            display: flex;
        }
        .equal_label {
            width: 30%;
            display: flex;
            align-items: center;
        }
        .equals_input {
            width: 70%;
        }
        .d-block-show-large{
            display: none !important;
        }
        .d-none-for-mobile{
            display: block !important;
        }
        .passenger-pd{
            padding-top: 10px;
        }
        .smallLineHeight {
            line-height: 25px;
        }
        .largeLineHeight {
            line-height: 25px;
        }
        .checkbox-mobile{
            display: none;
        }
        .name-width{
            width:72%;
        }
        .map-point-width{
            width:28%;
        }
        .details-screen{
            padding-top: 20px !important;
        }
        .mobileScreen{
            width: 97%;
            font-size: 14px;
        }
    }
    @media (width < 457px) {
        .select-radio .card-select-payment {
            padding: 8px;
        }
    }
    @media(width < 435px) {
        .passDetails .title-pass {
            font-size: 13px;
        }
    }
    @media(width < 400px) {
        .details-screen{
            padding-top: 20px !important;
        }
        .name-width{
            width:60%;
        }
        .map-point-width{
            width:40%;
        }
    }
   
    @media (width < 450px) {
        .noted-text {
            font-size: 14px;
        }
        /* .custom-select .selected:after{
            top: 26px !important;
        } */
        .custom-select{
            border-radius: 5px;
        }
        .mobileScreen{
            width: 96%;
            font-size: 14px;
        }
        /* .custom-select .items div{
            width: 90%;
            margin: 0px auto;
            border-bottom: 1px solid #DE5D0A;
        } */
       .rowScreen{
            margin-bottom: auto !important;
       }

        .custom-select .selected{
            line-height: 2;
            padding: 8px;
            
        }
        .custom-select .items{
            line-height: 2;
            font-size: 14px;
        }
        .custom-select .selected:after{
            top: 28px;
            font-weight: bold;
        }
    }
    .v-input--selection-controls__input input[role=checkbox]{
        opacity: 1 !important;
    }
    
    .hover-color:hover {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 12px;
        color: #ffffff;
        background: #DE5D0A;
    }
</style>