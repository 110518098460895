<!-- <template>
  <div class="resort">
    <div class="row slide-banner">
      <div class="col-lg-12">
        <vueper-slides :dragging-distance="50" :arrows="false" class="reson-height">
          <vueper-slide class="reson-height" style="border-radius:6px;"
            v-for="(dataResortBanner, i) in dataResortBanner" :key="i" :image="dataResortBanner.image"
            :title="dataResortBanner.title" />
        </vueper-slides>
      </div>
    </div>
    <div class="containers">

      <div class="row" style="padding-left: 12px; padding-right: 12px; padding-top: 12px"
        v-for="(dataResort, i) in dataResorts" :key="i">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row store-all">
            <div class="col-sm-5">
              <div class="img">
                <img v-if="dataResort.image" :src="dataResort.image" />
                <img v-else src="@/assets/placeholder-image.jpeg" />
              </div>
            </div>
            <div class="col-sm-7">
              <div class="titles">
                <span>
                  <a style="color: #312783" v-if="dataResort.linkWeb" :href="dataResort.linkWeb" target="_blank">{{
                    $t(dataResort.title) }}</a>
                  <a style="color: #312783" v-else>{{ $t(dataResort.title) }}</a>
                </span>
              </div>
              <div>
                <a v-if="dataResort.linkfb" :href="dataResort.linkfb" target="_blank">
                  <img style="cursor: pointer" :src="dataResort.iconFacebook" alt="" />
                </a>
                <a v-else>
                  <img style="cursor: pointer" :src="dataResort.iconFacebook" alt="" />
                </a>
                <a v-if="dataResort.linkte" :href="dataResort.linkte" target="_blank">
                  <img style="cursor: pointer; margin-left: 12px" :src="dataResort.iconTelegram" alt="" />
                </a>
                <a v-else>
                  <img style="cursor: pointer; margin-left: 12px" :src="dataResort.iconTelegram" alt="" />
                </a>
              </div>
              <p style="line-height: 1.5; padding-top: 14px" class="text-font-size">
                {{ $t(dataResort.description) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      dataResortBanner: [
        {
          image: require('@/assets/resort/cover.png'),
          title: 'Our Resorts'
        },

        // {
        //   image: require('@/assets/placeholder-image.jpeg'),
        // },
        // {
        //   image: require('@/assets/placeholder-image.jpeg'),
        // },
      ],
      dataResorts: [
        {
          image: require('@/assets/resort/06-Tube-Resort.jpg'),
          title: 'message.tubeResort',
          description: 'message.desTubeResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/TubeResort/",
          linkte: "https://t.me/tubebeachreosrt",
          linkWeb: "https://tuberesort.com/",
        },
        {
          image: require('@/assets/resort/07-Sandy-Beach-Resort.jpg'),
          title: 'message.sandyBeachBungalows',
          description: 'message.desSandyBeachBungalows',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/SandayBeachResort/",
          linkte: "",
          linkWeb: "https://sandybeachbungalows.com",
        },
        {
          image: require('@/assets/resort/03-Koh-Sdach-Resort.jpg'),
          title: 'message.kohSdachResort',
          description: 'message.desKohSdachResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/kohsdechresort/",
          linkte: "https://t.me/KohSdechResort",
          linkWeb: "https://kohsdachislandresort.com",
        },
        {
          image: require('@/assets/resort/08-Eden-Beach-Resort.jpg'),
          title: 'message.edenBeachResort',
          description: 'message.desEdenBeachResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/profile.php?id=100028389535319",
          linkte: "https://t.me/EdenBeachResort",
          linkWeb: "https://edenbeachresorts.com",
        },
        {
          image: require('@/assets/resort/05-Bodega-Resort.jpg'),
          title: 'message.bodegaResort',
          description: 'message.desBodegaResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/BodegaResort/",
          linkte: "https://t.me/Bodegabookings",
          linkWeb: "https://bodegabeachclub.com",
        },
        {
          image: require('@/assets/resort/04-Pidoma-Resort.jpg'),
          title: 'message.pidomaResort',
          description: 'message.desPidomaResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/pidomaresort/",
          linkte: "https://t.me/Pidomaresort",
          linkWeb: "https://pidomaresort.com",
        },
        {
          image: require('@/assets/resort/02-Romdoul-Kirirom-Resort.jpg'),
          title: 'message.romdoulKiriromResort',
          description: 'message.desRomdoulKiriromResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/profile.php?id=100091229344637",
          linkte: "https://t.me/RomdoulKiriromResort",
          linkWeb: "",
        },
        {
          image: require('@/assets/resort/10-Koh-Apikjun-Resort.jpg'),
          title: 'message.kohApikjunResort',
          description: 'message.desKohApikjunResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/KohApikjunResort",
          linkte: "https://t.me/KohApikjunResort",
          linkWeb: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.resort {
  margin-top: 50px;
  padding-bottom: 50px;
}

.mar-top {
  margin-top: 30px;
}

.store-all {
  background: #f6f7f8;
  border: 1px solid #c6c6c6;
  border-radius: 12px;
}

.img {
  width: 100%;
  height: 303px;
  object-fit: cover;
}

.slide-banner {
  margin-top: -60px;
}

.img img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.titles {
  width: 100%;
  padding-bottom: 10px;
  color: #312783;
  font-weight: 600;
  font-size: 20px;
}

.titles span:hover {
  text-decoration: underline;
}

.see-more {
  border: 1px solid #312783;
  width: 190px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
}

.reson-height {
  height: 400px;
}

@media (width < 600px) {
  .slide-banner {
    width: 92%;
    margin: 0px auto;
  }
}

@media (width < 500px) {
  .img {
    width: 100%;
    height: 100%;
  }

  .slide-banner {
    width: 92%;
    margin: 0px auto;
  }
}

@media (width < 450px) {
  .reson-height {
    height: 220px !important;
  }

  .text-font-size {
    font-size: 14px;
  }
}

@media (width < 351px) {
  .titles span {
    font-size: 16px;
  }

  .titles span img {
    width: 20px;
    height: 20px;
  }
}
</style> -->

<template>
  <div class="resort-listing">
    <div class="slider">
      <div class="slider-container">
        <img 
          v-for="(image, i) in sliderImages" 
          :key="i"
          :src="image"
          :class="{ active: currentIndex === i }"
          alt="Slider Image"
        />
      </div>
      <!-- <div class="slider-dots">
        <span 
          v-for="(_, i) in sliderImages" 
          :key="i"
          class="dot" 
          :class="{ active: currentIndex === i }"
          @click="setCurrentSlide(i)"
        ></span>
      </div> -->
      <!-- <button class="slider-btn prev" @click="prevSlide">&lt;</button> -->
      <!-- <button class="slider-btn next" @click="nextSlide">&gt;</button> -->
    </div>
<div style="display: flex; justify-content: center;">
    <div class="resort-list">
      <div v-for="resort in resorts" :key="resort.id" class="resort-card">
        <div class="resort-image">
          <img :src="resort.image" :alt="resort.name" />
        </div>

        <div class="resort-info">
          <div class="resort-header">
             <h3>{{ resort.name }}</h3>
            <div class="button-group">
               <button class="detail-btn" @click="handleRedirect(resort.RequiredUrl)">Book Now</button>  
            </div>
          </div>

          <div class="location" v-if="resort.location && resort.location.trim()">
            <svg xmlns="http://www.w3.org/2000/svg" class="location-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <p>{{ resort.location }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// import sliceshow from "@/assets/esperanza-inlet-sunrise-1080x480.jpg"
export default {
  name: 'ResortListing',
  data() {
    return {
      currentIndex: 0,
      sliderImages: [
        require('@/assets/resort/cover website _3.jpg'),
        // require('@/assets/image.jpg'),
        // require('@/assets/0204h12000a5tqdrv8D9D_R_960_660_R5_D.jpg')
      ],
      resorts: [
      {
            id: 1,
            name: 'Pidoma Resort',
            location: '',
            image: require('@/assets/resort/pidoma.jpg'),
            RequiredUrl: 'https://www.pidomaresort.com/'
          },
          {
            id: 2,
            name: 'One Beach Resort',
            location: '',
            image: require('@/assets/resort/one-beach.jpg'),
            RequiredUrl: 'https://onebeach-resort.com/'
          },
          {
            id: 3,
            name: 'Tube Resort',
            location: '',
            image: require('@/assets/resort/tube.jpg'),
            RequiredUrl: 'https://tube-resort.com/'
          },
          {
            id: 4,
            name: 'Romdoul Kirirom Resort',
            location: '',
            image: require('@/assets/resort/romdoul-kirirom.jpg'),
            RequiredUrl: 'https://romdoulresort.com/'
          },
          {
            id: 5,
            name: 'Romhaey Kirirom Resort',
            location: '',
            image: require('@/assets/resort/Romhaey.jpg'),
            RequiredUrl: 'https://romhaeykiriromresort.com/'
          },
          {
            id: 6,
            name: 'Camping Park Resort',
            location: '',
            image: require('@/assets/resort/Campingpark.jpg'),
            RequiredUrl: 'https://campingpark-kirirom.com/'
          },
          {
            id: 7,
            name: 'Koh Sdach Resort',
            location: '',
            image: require('@/assets/resort/koh-sdach.jpg'),
            RequiredUrl: 'https://www.kohsdachislandresort.com/'
          }
      ]
    }
  },
  methods: {
    handleDetailClick(resort) {
      this.$router.push(`/resort/${resort.id}`)
    },
    handleRedirect(url) {
  window.open(url, '_blank');
    },
    nextSlide() {
      if (this.slides && this.slides.length > 0) {
  // Proceed with your code logic
// } else {
//   console.error("Slides array is undefined or empty.");
}
      
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.sliderImages.length) % this.sliderImages.length
    },
    setCurrentSlide(index) {
      this.currentIndex = index
    }
  },
  mounted() {
    setInterval(this.nextSlide, 5000)
  }
}
</script>

<style scoped>
/* Main Listing Container */
.resort-listing {
max-width: 100%;
margin: 0 auto;
background-color: #fff;
font-family: Arial, sans-serif;
margin-bottom: 60px;
}

/* Slider Styles */
.slider {
position: relative;
width: 100%;
height: 380px;
overflow: hidden;
padding-top: 20px;
}

.slider-container img {
position: absolute;
width: 100%;
height: 100%;
opacity: 0;
transition: opacity 0.5s ease-in-out;
}

.slider-container img.active {
opacity: 1;
}

.slider-dots {
position: absolute;
bottom: 16px;
left: 0;
right: 0;
display: flex;
justify-content: center;
gap: 8px;
}

.dot {
width: 8px;
height: 8px;
border-radius: 50%;
background-color: rgba(255, 255, 255, 0.6);
cursor: pointer;
transition: background-color 0.3s ease;
}

.dot.active {
background-color: #fff;
}

/* Resort List as Grid - Update the base styles */
.resort-list {
  display: grid;
  gap: 24px;
  padding: 16px;
  width: 100%;
  /* max-width: 100%; */
  margin: 0 auto;
}

.resort-card {
background-color: #fff;
border-radius: 8px;
overflow: hidden;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
transition: transform 0.3s;
}

.resort-card:hover {
transform: scale(1.08);
}

.resort-image {
width: 100%;
height: 192px;
overflow: hidden;
/* margin-bottom: 16px; */
}

.resort-image img {
width: 100%;
height: 100%;
object-fit: cover;
}

/* Info Section */
.resort-info {
padding: 12px;
color: #333;
}

.resort-header {
display: flex;
justify-content: space-between;
align-items: center;
/* margin-bottom: 8px; */
/* padding-top: 12px; */
}

.resort-header h3 {
font-size: 16px;
margin: 0;
font-weight: 600;
}

.detail-btn {
padding: 4px 12px;
background-color: #f3f4f6;
border: none;
border-radius: 6px;
cursor: pointer;
transition: background-color 0.2s;
font-weight: 600;
}

.detail-btn:hover {
background-color: #DE5D0A;
color: white;
}

.location {
display: flex;
align-items: flex-start;
gap: 8px;
}

.location-icon {
width: 20px;
height: 20px;
margin-top: 4px;
flex-shrink: 0;
}

.location p {
font-size: 14px;
margin: 0;
line-height: 1.5;
}

/* Responsive Styles */
@media (min-width: 1024px) {
  .resort-list {
    grid-template-columns: repeat(3, 1fr);
    width: 1154px;
  }

  .slider {
    height: 380px;
  }

  .resort-image {
    height: 192px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .resort-list {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    max-width: 768px;
    padding: 16px 24px;
  }

  .slider {
    height: 300px;
  }

  .resort-image {
    height: 180px;
  }

  .resort-header h3 {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .resort-list {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 16px;
  }

  .slider {
    height: 250px;
    padding-top: 10px;
  }

  .resort-image {
    height: 200px;
  }

  .resort-card {
    max-width: 100%;
  }

  .resort-header h2 {
    font-size: 16px;
  }

  .location p {
    font-size: 13px;
  }
}

.button-group {
display: flex;
  justify-content: center;
  align-items: center;
/* gap: 8px; */
}

.redirect-btn {
padding: 4px 12px;
background-color: #4CAF50;
color: white;
border: none;
border-radius: 6px;
cursor: pointer;
transition: background-color 0.2s;
}

.redirect-btn:hover {
background-color: #45a049;
}

.slider-btn {
position: absolute;
top: 50%;
transform: translateY(-50%);
background: rgba(255, 255, 255, 0.5);
border: none;
/* padding: 10px 15px; */
cursor: pointer;
border-radius: 50%;
font-size: 18px;
}

.slider-btn:hover {
background: rgba(255, 255, 255, 0.8);
}

.prev {
left: 10px;
}

.next {
right: 10px;
}
</style>