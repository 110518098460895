<template>
    <div class="container">
        <div>
            <!-- Dynamically bind the src attribute to the imported image -->
            <img style="height: 48px;" :src="VETimg" alt="VET Image" />
            <div  class="termscondition">
                 <h3>លក្ខខណ្ឌក្នុងការអនុវត្តន៍</h3>
            <ul>
                <li> អ្នកដំណើរ ត្រូវមកដល់ស្ថានីយ៍ចេញដំណើរ និងធ្វើការ Check-in ជាមួយបុគ្គលិក ១៥នាទី មុនម៉ោងចេញដំណើរ។</li>
                <li> អ្នកដំណើរដែលមកដល់ស្ថានីយ៍ចេញដំណើរយឺត(ក្រោយម៉ោងចេញដំណើរ) មិនអាចធ្វើការតវ៉ា ឬទាមទារប្តូរសំបុត្រជាប្រាក់វិញបានទេ។ សំបុត្រនឹងផុតសុពលភាពបន្ទាប់ពីរថយន្តចេញដំណើរ។</li>
                <li>សំបុត្រទិញរួច មិនអាចប្តូរជាប្រាក់វិញបានទេ។ ការសុំ OPENសំបុត្រ ឬប្តូរព័ត៌មានធ្វើដំណើរអាចធ្វើបានក្នុងរយៈពេល ១២ម៉ោងមុនពេលចេញដំណើរ ដោយត្រូវធ្វើការទំនាក់ទំនងមកស្ថានីយ៍ចេញដំណើរដោយផ្ទាល់។</li>
                <li> ក្មេងអាយុលើសពី ៣ឆ្នាំ ត្រូវទិញសំបុត្រក្នុងតម្លៃពេញចំពោះឡានកៅអីអង្គុយ (ចំពោះក្មេងអាយុក្រោម៣ឆ្នាំមិនតម្រូវអោយទិញសំបុត្រនោះទេ តែត្រូវអង្គុយលើភ្លៅអាណាព្យាបាល ដោយមិនត្រូវបង្កការរំខានដល់អ្នកដំណើរដែលអង្គុយកៅអីជាប់នោះឡើយ)។ចំពោះឡានគ្រែគេង កូនក្មេងត្រូវទិញសំបុត្រក្នុងតម្លៃពេញ។ ក្មេងអាយុក្រោម ១៥ឆ្នាំ ត្រូវមានអាណាព្យាបាលរួមដំណើរជាមួយដើម្បីមើលការខុសត្រូវ។</li>
                <li> អ្នកដំណើរ គួរទិញសំបុត្រធ្វើដំណើរមុន មួយថ្ងៃ ដើម្បីកុំអោយមានការខកខានចំពោះការធ្វើដំណើរ។</li>
                <li>អ្នកដំណើរអាចយកអីវ៉ានតាមខ្លួនក្នុងទំងន់មិនលើស៣០គីឡូក្រាម។ អីវ៉ាន់ដែលមានទំហំធំ ឬលើសកំណត់នឹងត្រូវគិតថ្លៃសេវាបន្ថែម។</li>
                <li>សត្វចិញ្ចឹម អាវុធជាតិផ្ទុះ ទំនិញខុសច្បាប់ គ្រឿងញៀន វត្ថុងាយឆាបឆេះ ម្ហូបអាហារ ឬផ្លែឈើមានក្លិន មិនត្រូវបានអនុញាតិ អោយយកចូលក្នុងរថយន្តឡើយ។</li>
                <li>ក្រុមហ៊ុនទទួលខុសត្រូវលើសេវាកម្មដឹកអ្នកដំណើរ។ រាល់ឯកសារចាំបាច់សម្រាប់នីតិវិធីឆ្លងដែនជាការទទួលខុសត្រូវរបស់អ្នកដំណើរ ។សំបុត្រទិញរួចមិនអាចប្តរជាលុយបាននោះទេ ក្នុងករណីអ្នកដំណើរមិនអាចឆ្លងដែនបាន ហើយសំបុត្រដែលបានទិញរួចនឹងត្រូវផុតសុពលភាពក្រោយឡានចេញដំណើរ ។</li>
                <li>ក្រុមហ៊ុនមិនទទួលខុសត្រូវចំពោះទ្រព្យសម្បត្តិឯកជនរបស់អ្នកដំណើរឡើយ។ ការយកគ្រឿងស្រវឹងចូលរថយន្ត ឬទទួលទានគ្រឿងស្រវឹងក្នុងរថយន្តត្រូវបានហាមឃាត់។ ក្រុមហ៊ុនរក្សាសិទ្ធិក្នុងការបណ្តេញអោយចុះពីរថយន្តចំពោះអ្នកដំណើរដែលស្រវឹង អ្នកដំណើរដែលគ្មានអនាម័យ ឬអ្នកដំណើរដែលមានឥរិយាបថមិនសមរម្យបង្កការរំខានដល់អ្នកដំណើរផ្សេងទៀតក្នុងរថយន្ត ដោយក្រុមហ៊ុនមិនទូទាត់ថ្លៃសំបុត្រជូនវិញនោះទេ។</li>
            </ul>
            </div>
           
        </div>
        <div>
            <img style="height: 48px;" :src="VETimg" alt="VET Image" />
            <div class="termscondition">
                 <h3>Terms and Conditions</h3>
            <ul>
                <li> Passengers are required to arrive at the boarding point 15 minutes before the scheduled departure time for check in.</li>
                <li>It is the passengers' responsibility to ensure early or on-time arrival at the boarding station. Missed schedules will not be eligible for refunds. The purchase tickets will be expired after the bus departure.</li>
                <li>Purchased tickets are non-refundable but can be used as a coupon for future trips. Coupon refunds can be processed up to 12 hours before departure schedule. The Coupon valid three months this reschedule can be made only time with the same route and vehicle type.</li>
                <li>For crossing border Vireak Buntham Express Travel is not responsible for VISA entry to the country, in case any immigration rejects at the border, passenger will not eligible for refund the ticket.</li>
                <li>Infants under the age of 3 are not required to have tickets. They must be accompanied by an adult and sit on their lap. Children aged 3 and above must purchase their own seats at the full price. But for sleeping bus infant require to have their bed.</li>
                <li> Passengers can choose their preferred seats and purchase real-time tickets through the VET Express Mobile APP or website at any time before schedule.</li>
                <li>Passengers are allowed a maximum of 25kg of checked baggage and one carry-on item. Additional charges will apply for overweight baggage, based on the local delivery fee.</li>
                <li> Items such as strong-smelling food (e.g., durian), pets, firearms, narcotics, smuggled or illegal products, and other dangerous or flammable/explosive items) are strictly prohibited from being brought onboard.</li>
                <li>Passengers are responsible for the safety of their personal belongings and valuables. Vireak Buntham Express Travel will not be held liable for any loss or damage to personal items.
                    Bringing alcohol into the vehicle or consuming alcohol inside the vehicle is prohibited. The company reserves the right to deboard any passengers who are intoxicated, unhygienic, or displaying inappropriate behavior that causes disturbance to other passengers in the vehicle and will not eligible to refund.</li>
            </ul>
            </div>
           
        </div>
        <div>
            <img style="height: 48px;" :src="VETimg" alt="VET image">
            <div>
                <h3>条款和条件</h3>
                <ul>
                    <li>乘客必须在预定出发时间前15分钟到达登车点办理登车手续。</li>
                    <li>乘客要确保提前或准时到达登车站。如果未能按时到达预定时间，恕不退款。已经购买的车票将在巴士出发后失效。</li>
                    <li>已购买的车票不可退款。乘客可以在出发前 12 小时联系出发车站的服务员申请出机票或更换行程信息。</li>
                    <li>3岁以下的婴儿无需购票,但须由成人陪同并坐在成人大腿上并不打扰邻座乘客。3岁及以上的儿童需按全价购买座位。但对于卧铺巴士,婴儿需要有自己的床铺。</li>
                    <li>乘客可以通过 VET Express App或网站选择自己喜欢的座位并购买实时车票,直至出发前。乘客应提前一天购票，以免错过行程。</li>
                    <li>乘客最多可带 30 公斤的行李和一件随身包。超重行李将根据当地配送费用收取额外费用。</li>
                    <li>严禁携带如臭味强烈的食物（例如榴莲）、宠物、火器、毒品、走私或非法物品以及其他危险、易燃/爆炸物品等登车。</li>
                    <li>对于跨境行程,VET不对签证入境负责。如果在边境遇到移民拒绝入境的情况下,乘客将不再有资格获得车票退款。</li>
                    <li>乘客对个人物品和贵重物品的安全负责。VET 对任何个人物品的丢失或损坏不承担责任。
                        禁止将酒精带入车辆或在车内饮酒。公司保留在乘客醉酒、不卫生或表现出不当行为、扰乱其他乘客的情况下，要求其下车的权利，并且此类乘客将无法获得退款</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import VETimg from '@/assets/icon_bars/Picture1.jpg';

export default {
    name: 'TermsConditionTicket',
    data() {
        return {
            VETimg, // Expose the imported image for use in the template
        };
    },
};
</script>

<style>
.termscondition{
    padding: 15px;
    text-align: justify;
}
.container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    padding-bottom: 50px;
}
ul li{
    line-height: 26px;
}
</style>
